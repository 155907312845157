.icon-arrow-down {
  width: 1.84em;
  height: 1em;
  fill: initial;
}
.icon-arrow-next {
  width: 0.54em;
  height: 1em;
  fill: initial;
}
.icon-arrow-prev {
  width: 0.54em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-mail {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-mobile {
  width: 0.6em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-skype {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-youtube {
  width: 1.41em;
  height: 1em;
  fill: initial;
}
