@mixin d {
  @media only screen and (max-width: "1229px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "666px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&subset=cyrillic');
@mixin f {
  font-family: 'Montserrat', sans-serif; }

$black: #2a2242;
$white: #fff;
$pink: #ff3366;
$border: #ABB0BA;

body {
  min-width: 320px;
  @include f;
  font-size: 16px;
  line-height: 1.3;
  // -webkit-font-smoothing: antialiased
  // -moz-osx-font-smoothing: grayscale
  color: $black;
  @include a {
    line-height: 1.2; }
  &.no-scroll {
    overflow: hidden; } }

button {
  background: none; }

a {
  text-decoration: none; }

.out {
  overflow: hidden; }

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
  @include t {
    padding: 0 20px; }
  @include m {
    padding: 0 17px; } }

.btn {
  height: 35px;
  padding: 8px 18px;
  @include f;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
  transition: all .3s;
  &_pink {
    background: $pink;
    color: $white;
    &:hover {
      background: lighten($pink, 8); } }
  &_border-white {
    position: relative;
    border: solid 2px $white;
    color: $white;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      transition: all .3s; }
    &:before {
      background-image: linear-gradient(295deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.0)); }
    &:after {
      background-image: linear-gradient(295deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.0));
      visibility: hidden;
      opacity: 0; }
    &:hover:after {
      visibility: visible;
      opacity: 1; } }
  &_border-pink {
    border: 2px solid $pink;
    color: $pink;
    &:hover {
      background: $pink;
      color: $white; } }
  &_border-grey {
    border: 1px solid $border;
    color: $border;
    &:hover {
      background: $border;
      color: $white; } } }

a.btn {
  display: flex;
  justify-content: center;
  align-items: center; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 36px;
    background: rgba($white, .2);
    @include m {
      display: none; } }
  &__center {
    position: relative;
    z-index: 2;
    display: flex;
    @include m {
      padding: 0; } }
  &__logo {
    flex: 0 0 185px;
    width: 185px;
    font-size: 0;
    @include d {
      flex: 0 0 140px;
      width: 140px; }
    @include t {
      flex: 0 0 100px;
      width: 100px; }
    @include m {
      flex: 0 0 120px;
      width: 120px; } }
  &__container {
    flex: 0 0 calc(100% - 185px);
    width: calc(100% - 185px);
    @include d {
      flex: 0 0 calc(100% - 140px);
      width: calc(100% - 140px); }
    @include t {
      flex: 0 0 calc(100% - 100px);
      width: calc(100% - 100px); }
    @include m {
      flex: 0 0 calc(100% - 120px);
      width: calc(100% - 120px); } }
  &__logo &__pic {
    width: 100%; }
  &__top {
    display: flex;
    align-items: center;
    padding-left: 10px;
    @include m {
      justify-content: flex-end;
      height: 70px;
      margin-bottom: 40px;
      padding: 0 17px; } }
  &__nav {
    display: flex;
    margin-right: auto;
    @include m {
      display: none;
      position: absolute;
      top: 70px;
      right: 0;
      z-index: 10;
      width: 200px;
      padding: 15px 20px;
      border-width: 0 1px 2px;
      border-style: solid;
      border-color: $pink;
      background: $white; } }
  &__nav &__link {
    padding: 10px 16px 8px;
    font-size: 14px;
    color: $white;
    transition: all .3s;
    @include d {
      padding: 9px 12px 8px;
      font-size: 13px; }
    @include t {
      padding: 9px 5px 8px;
      font-size: 13px; }
    @include m {
      display: block;
      padding: 0;
      font-size: 14px;
      color: $black; }
    &:hover {
      color: lighten($pink, 25);
      @include m {
        color: $black; } }
    &:not(:last-child) {
      @include m {
        margin-bottom: 20px; } } }
  &__social {
    margin-right: 27px;
    padding-top: 2px;
    @include t {
      display: none; } }
  &__language {
    display: flex;
    @include m {
      display: none; } }
  &__language &__link {
    position: relative;
    padding: 10px 15px 8px;
    background: rgba($white, .2);
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    transition: all .3s;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 0;
      height: 3px;
      margin: 0 auto;
      background: $pink;
      transition: all .3s; }
    &.active {
      background: #f1f2f4;
      color: #586377; }
    &.active:before {
      width: 100%; } }
  &__body {
    display: flex;
    align-items: center;
    padding: 20px 34px 14px 26px;
    @include d {
      padding: 25px 34px 19px 26px; }
    @include t {
      padding: 8px 15px; }
    @include m {
      margin-left: -120px;
      padding: 15px 20px; } }
  &__info {
    margin-right: auto;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    @include t {
      font-size: 13px; }
    @include m {
      font-size: 12px;
      text-transform: none;
      line-height: 1.2; }
    br {
      @include m {
        display: none; } } }
  &__phones {
    display: flex;
    flex-direction: column;
    @include m {
      display: none;
      position: absolute;
      top: 70px;
      right: 0;
      flex-direction: column;
      width: 200px;
      padding: 15px 20px;
      border-width: 0 1px 2px;
      border-style: solid;
      border-color: $pink;
      background: $white; } }
  &__phone {
    font-size: 20px;
    line-height: 1.2;
    color: $white;
    transition: all .3s;
    @include t {
      font-size: 16px; }
    @include m {
      font-size: 14px;
      color: $black; }
    &:hover {
      color: lighten($pink, 25);
      @include m {
        color: $black; } }
    &:not(:last-child) {
      margin-bottom: 2px;
      @include m {
        margin-bottom: 10px; } } }
  &__bot {
    display: flex;
    align-items: flex-start;
    @include d {
      margin-left: -140px; }
    @include t {
      margin-left: -100px; } }
  &__menu {
    flex: 0 0 calc(100% - 310px);
    width: calc(100% - 310px);
    @include t {
      flex: 0 0 calc(100% - 200px);
      width: calc(100% - 200px); }
    @include m {
      display: none;
      position: absolute;
      top: 110px;
      right: 0;
      left: 120px;
      flex: 0 0 auto;
      width: auto; } }
  &__call {
    flex: 0 0 310px;
    width: 310px;
    padding-left: 15px;
    @include t {
      flex: 0 0 200px;
      width: 200px;
      padding-left: 15px; }
    @include m {
      display: none; } }
  &__btn {
    display: none;
    font-size: 0;
    @include m {
      display: inline-block; }
    .icon {
      font-size: 25px;
      fill: $white; } }
  &__services {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    left: 120px;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: $white;
    border-bottom: 2px solid $pink;
    @include m {
      display: flex; } }
  &__icon {
    margin-right: 10px;
    font-size: 0; }
  &__img {
    width: 15px; }
  &__services &__text {
    position: relative;
    margin: 0;
    padding: 2px 20px 0 0;
    font-size: 14px;
    text-transform: uppercase;
    color: $black;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 10px;
      height: 6px;
      margin-top: -2px;
      background: url('../img/arrow-down-black.svg') no-repeat 0 0 / 10px 6px;
      transition: all .3s; } }
  &__services.active &__text:before {
    transform: rotate(180deg); } }

.burger {
  display: none;
  position: relative;
  z-index: 20;
  width: 30px;
  height: 17px;
  margin-left: 30px;
  text-align: center;
  @include m {
    display: inline-block; }
  &__lines {
    position: relative;
    display: block;
    width: 24px;
    height: 2px;
    margin: 7px 0 0;
    background: $white;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      height: 2px;
      background: $white;
      transition: all .2s; }
    &:before {
      top: -7px;
      width: 30px; }
    &:after {
      bottom: -7px;
      width: 17px; } }
  &.active &__lines {
    top: 0;
    transform: rotate(-44deg);
    width: 24px; }
  &.active &__lines:before {
    top: 0;
    transform: rotate(86deg);
    width: 24px; }
  &.active &__lines:after {
    top: 0;
    width: 34px;
    opacity: 0;
    transform: rotate(180deg); } }

.call {
  position: relative;
  &__btn {
    width: 100%;
    height: 50px;
    @include t {
      padding: 0;
      font-size: 14px; } }
  &__wrap {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-top: 30px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: all .3s;
    @include t {
      left: auto;
      width: 220px; }
    &.visible {
      visibility: visible;
      opacity: 1;
      transform: translateY(0); } }
  &__arrow {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0;
    @include t {
      left: auto;
      right: 89px;
      transform: translateX(0); }
    .icon {
      font-size: 7px;
      fill: $white; } }
  &__form {
    padding: 30px 20px 30px;
    background: #F1F2F4;
    @include t {
      padding: 25px 10px; } }
  &__field:not(:last-child) {
    margin-bottom: 20px; }
  &__fieldset {
    margin-bottom: 30px; }
  &__form &__btn {
    width: auto;
    height: 40px;
    padding: 0 14px; } }

.field {
  &__label {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 16px;
    @include a {
      margin-bottom: 8px; } }
  &__input,
  &__textarea {
    width: 100%;
    border: 1px solid $border;
    background: $white;
    @include f;
    font-size: 16px;
    color: $black;
    @include placeholder {
      color: $black; }
    &:focus {
      border-color: $pink; } }
  &__input {
    height: 40px;
    padding: 0 15px; }
  &__textarea {
    height: 136px;
    padding: 10px 12px;
    resize: none;
    @include a {
      height: 100px; } } }

.content {
  h2 {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 400;
    @include t {
      font-size: 25px; }
    @include m {
      font-size: 23px; }
    @include a {
      margin-bottom: 25px;
      font-size: 20px; } }
  h3 {
    margin-bottom: 20px;
    padding-left: 18px;
    border-left: 3px solid $pink;
    font-size: 24px;
    font-weight: 400;
    @include a {
      padding-left: 15px;
      font-size: 20px; } }
  p {
    font-weight: 300;
    @include a {
      font-size: 14px; }
    &:not(:last-child) {
      margin-bottom: 15px; } }
  blockquote {
    padding-left: 20px;
    border-left: 3px solid $pink;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    @include t {
      font-size: 20px; }
    @include a {
      padding-left: 15px;
      border-width: 2px;
      font-size: 16px; }
    &:not(:last-child) {
      margin-bottom: 30px;
      @include m {
        margin-bottom: 25px; }
      @include s {
        margin-bottom: 20px; } } }
  small {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    color: #8190b0;
    &:not(:last-child) {
      margin-bottom: 30px;
      @include a {
        margin-bottom: 20px; } } }
  big {
    font-size: 20px;
    font-weight: 300;
    color: $border;
    @include a {
      font-size: 16px; } }
  figure[align="center"] {
    font-size: 0;
    img {
      max-width: 100%; }
    &:not(:last-child) {
      margin-bottom: 40px;
      @include a {
        margin-bottom: 20px; } } }
  figure[align="right"] {
    float: right;
    margin: 0 0 20px 20px;
    @include t {
      width: 45%; }
    @include a {
      float: none;
      width: 100%;
      margin: 0 0 20px 0; }
    img {
      @include t {
        width: 100%; } } }
  figure[align="left"] {
    float: left;
    margin: 0 20px 20px 0;
    @include t {
      width: 45%; }
    @include a {
      float: none;
      width: 100%;
      margin: 0 0 20px 0; }
    img {
      @include t {
        width: 100%; } } }
  ul {
    padding-left: 25px;
    list-style-type: square;
    @include a {
      padding-left: 20px; }
    li {
      font-size: 16px;
      line-height: 1.3;
      @include a {
        font-size: 14px; }
      &:not(:last-child) {
        margin-bottom: 15px;
        @include a {
          margin-bottom: 8px; } } }
    &:not(:last-child) {
      margin-bottom: 40px;
      @include a {
        margin-bottom: 25px; } } }
  ul+hr {
    margin-top: -20px;
    @include a {
      margin-top: 0; } }
  strong {
    display: block;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 400;
    @include m {
      font-size: 24px; }
    @include a {
      margin-bottom: 12px;
      font-size: 18px; } }
  hr {
    margin: 20px -15px 40px;
    @include m {
      margin-bottom: 30px; }
    @include a {
      margin: 20px 0 30px; } }
  b {
    font-weight: 700; }
  table {
    max-width: 620px;
    margin: 0 0 20px 10px;
    border-collapse: separate;
    border-spacing: 10px 0;
    @include m {
      margin-left: 0; }
    @include a {
      margin: 0 0 20px 0;
      border-spacing: 5px 0; } }
  td {
    padding: 10px 0 7px;
    font-size: 14px;
    color: $black;
    @include a {
      font-size: 13px; }
    &:first-child {
      border-bottom: 1px solid $black;
      font-weight: 600; }
    &:nth-child(2) {
      border-bottom: 1px solid #7F7A8E;
      font-weight: 300; } } }

.social {
  display: flex;
  align-items: center;
  &__link {
    font-size: 0;
    .icon-facebook {
      font-size: 16px; }
    .icon-instagram {
      font-size: 18px; }
    .icon-youtube {
      font-size: 14px; }
    &:not(:last-child) {
      margin-right: 10px; } }
  &__item {
    font-size: 0;
    &:not(:last-child) {
      margin-right: 18px; } }
  &__item:hover &__pic {
    transform: scale(1.1); }
  &__pic {
    width: 32px;
    transition: all .3s;
    @include a {
      width: 22px; } }
  &_white &__link .icon {
    fill: #f1f2f4;
    transition: all .3s; }
  &_white &__link:hover .icon {
    fill: lighten($pink, 25); }
  &_black &__link .icon {
    fill: $black;
    transition: all .3s; }
  &_black &__link:hover .icon {
    fill: lighten($black, 25); } }

.menu {
  display: flex;
  @include m {
    flex-direction: column;
    // width: 200px
    padding: 15px 20px;
    border-bottom: 2px solid $pink;
    background: $white; }
  &__in {
    display: flex;
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
    @include t {
      flex: 0 0 calc(100% - 90px);
      width: calc(100% - 90px); }
    @include m {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      width: 100%; } }
  &__action {
    position: relative;
    flex: 0 0 120px;
    width: 120px;
    padding: 16px 20px 15px 5px;
    background: #f1f2f4;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    color: $pink;
    transition: all .3s;
    @include t {
      flex: 0 0 90px;
      width: 90px;
      padding: 16px 5px 15px 5px; }
    @include m {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
      text-align: left;
      background: none;
      font-size: 14px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: $pink;
      @include m {
        display: none; } }
    &:hover {
      color: lighten($pink, 15);
      &:before {
        background: lighten($pink, 15); } } }
  &__filter {
    position: relative;
    flex: 1 0 auto;
    @include m {
      flex: 0 0 auto;
      margin-bottom: 20px; }
    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin: 12px 0 0 -6px;
      width: 12px;
      height: 7px;
      background: url('../img/arrow-down.svg') no-repeat 0 0 / 12px 7px; } }
  &__filter.active &__box {
    display: block;
    @include m {
      display: none; } }
  &__filter.active .menu__text {
    background: $white;
    @include m {
      background: none; }
    &:before {
      width: 100%;
      @include m {
        width: 0; } } }
  &__filter.active:before {
    display: block;
    @include m {
      display: none; } }
  &__filter:first-child &__stage {
    padding-left: 9px;
    @include m {
      padding-left: 0; } }
  &__stage {
    position: relative;
    display: block;
    padding: 0 13px;
    background: #f1f2f4;
    @include t {
      padding: 0; }
    @include m {
      background: none; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -5px;
      height: 12px;
      width: 1px;
      background: $border;
      @include m {
        display: none; } } }
  &__text {
    position: relative;
    padding: 16px 5px 15px;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    color: $black;
    transition: all .3s;
    @include t {
      font-size: 15px; }
    @include m {
      padding: 0;
      text-align: left;
      font-size: 14px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      margin: 0 auto;
      background: $pink;
      transition: all .3s; } }
  &__box {
    display: none;
    position: absolute;
    top: 100%;
    padding-top: 30px;
    border-bottom: 2px solid $black;
    @include m {
      position: relative;
      top: auto;
      padding-top: 15px;
      border: none; } }
  &__item {
    position: relative;
    &:not(:last-child) {
      @include m {
        margin-bottom: 10px; } } }
  &__category {
    position: relative;
    display: block;
    width: 225px;
    padding: 15px 20px;
    background: #f1f2f4;
    line-height: 1.2;
    color: $black;
    @include m {
      width: 100%;
      padding: 0;
      background: none;
      font-size: 14px; }
    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 50%;
      right: 8px;
      width: 6px;
      height: 12px;
      margin-top: -6px;
      background: url('../img/arrow-right.svg') no-repeat 0 0 / 6px 12px;
      @include m {
        display: none; } } }
  &__item:not(:last-child) &__category:after {
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    height: 1px;
    background: $border;
    @include m {
      display: none; } }
  &__list {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    padding-left: 6px;
    &:before {
      content: "";
      position: absolute;
      left: 6px;
      right: 0;
      bottom: -2px;
      height: 2px;
      background: $black; } }
  &__link {
    position: relative;
    display: block;
    width: 228px;
    padding: 15px 20px;
    background: #f1f2f4;
    line-height: 1.2;
    color: $black;
    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 50%;
      right: 8px;
      width: 6px;
      height: 12px;
      margin-top: -6px;
      background: url('../img/arrow-right.svg') no-repeat 0 0 / 6px 12px; } }
  &__link:not(:last-child):after {
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: -1px;
    height: 2px;
    background: $border; } }

body:not(.touch-device) .menu__filter:hover .menu__box {
  display: block; }

body:not(.touch-device) .menu__filter:hover .menu__text {
  background: $white;
  &:before {
    width: 100%; } }

body:not(.touch-device) .menu__filter:hover:before {
  display: block; }

body:not(.touch-device) .menu__item:hover .menu__category {
  background: $white;
  box-shadow: inset 3px 0 0 $pink; }

body:not(.touch-device) .menu__item:hover .menu__category:before {
  display: block; }

body:not(.touch-device) .menu__item:hover .menu__list {
  display: block; }

body:not(.touch-device) .menu__link:hover {
  background: $white;
  box-shadow: inset 3px 0 0 $pink; }

body:not(.touch-device)  .advantages__preview:hover .advantages__pic {
  transform: scale(1.2); }

.home {
  position: relative;
  padding: 240px 0 190px;
  background: linear-gradient(152deg, rgba(255, 51, 102, 0), rgba(255, 51, 102, .25)), linear-gradient(0deg, rgba(42, 35, 66, .7), rgba(42, 35, 66, .7)), url('../img/bg-home.jpg') no-repeat 50% 0 / cover;
  @include d {
    padding: 220px 0 190px; }
  @include t {
    padding: 190px 0 210px; }
  @include m {
    padding: 170px 0 190px;
    background-image: linear-gradient(147deg, #9E2B56, #2a2242); }
  @include a {
    padding: 180px 0 190px; }
  &__slider {
    margin-bottom: 70px;
    @include t {
      margin-bottom: 50px; } }
  &__wrap {
    width: 62%;
    margin-left: auto;
    @include d {
      width: 75%; }
    @include t {
      width: 90%; }
    @include s {
      width: 100%; } }
  &__title {
    position: relative;
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 400;
    color: $white;
    @include a {
      font-size: 30px; }
    @include s {
      margin-bottom: 13px;
      padding-left: 25px;
      font-size: 24px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -43px;
      width: 23px;
      height: 47px;
      background: url('../img/symbol.svg') no-repeat 0 0 / 23px 47px;
      @include a {
        width: 17px;
        height: 35px;
        background-size: 17px 35px; }
      @include s {
        left: 0; } } }
  &__info {
    font-size: 20px;
    font-weight: 300;
    color: $border;
    @include a {
      font-size: 16px; }
    @include s {
      padding-left: 25px; } } }

.slider {
  padding-bottom: 30px;
  @include s {
    margin: 0 -17px 25px; }
  &__list {
    padding: 0 52px;
    @include a {
      padding: 0; } }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 45px;
    @include t {
      padding: 0; }
    &:before {
      content: "";
      position: absolute;
      top: 18px;
      right: 0;
      left: 0;
      z-index: -1;
      bottom: 16px;
      border-bottom: 2px solid $pink;
      background: rgba(#f1f2f4, .2);
      @include d {
        top: 0;
        bottom: 0; } } }
  &__preview {
    flex: 0 0 473px;
    width: 473px;
    font-size: 0;
    @include d {
      flex: 0 0 350px;
      width: 350px; }
    @include t {
      flex: 0 0 100%;
      width: 100%; } }
  &__wrap {
    flex: 0 0 calc(100% - 473px);
    width: calc(100% - 473px);
    padding: 40px 70px 40px 60px;
    color: $white;
    @include d {
      flex: 0 0 calc(100% - 350px);
      width: calc(100% - 350px);
      padding: 40px 70px 40px 60px; }
    @include t {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      flex: 0 0 100%;
      width: 100%;
      padding: 30px;
      background: rgba($black, .5); }
    @include m {
      padding: 25px; }
    @include a {
      padding: 15px 20px; } }
  &__pic {
    width: 100%; }
  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.5;
    @include t {
      font-size: 22px; }
    @include a {
      font-size: 18px; }
    @include s {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 1.2; } }
  &__info {
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 1.22;
    @include d {
      margin-bottom: 30px; }
    @include t {
      display: none; } }
  &__btn {
    width: 228px;
    height: 50px;
    @include a {
      width: 131px;
      height: 34px;
      padding: 0;
      border-width: 1px;
      font-size: 14px; } }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 45px;
    bottom: 45px;
    width: 40px;
    background: rgba($white, .1);
    transition: all .3s;
    font-size: 0;
    @include a {
      top: auto;
      bottom: 15px;
      width: 65px;
      height: 34px;
      background: $black; }
    .icon {
      font-size: 28px;
      fill: $white;
      @include a {
        font-size: 16px; } }
    &:hover {
      background: rgba($white, .3);
      @include a {
        background: $black; } } }
  .owl-prev {
    left: 0;
    @include a {
      left: auto;
      right: 83px; } }
  .owl-next {
    right: 0;
    @include a {
      right: 15px; } }
  .owl-dots {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    display: flex;
    @include s {
      padding: 0 17px; }
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      right: 0;
      z-index: -1;
      height: 2px;
      background: rgba($white, .2);
      @include s {
        top: 1px; } } }
  .owl-dot {
    flex: 1 0 auto;
    height: 6px;
    transition: all .3s;
    @include s {
      height: 4px; }
    &.active {
      background: $pink; } } }

.title {
  padding-left: 20px;
  border-left: 3px solid $pink;
  font-size: 24px;
  font-weight: 400;
  &_fs18 {
    padding-left: 12px;
    font-size: 18px; }
  &_fs20 {
    padding-left: 18px;
    font-size: 20px; }
  &_white {
    color: $white; } }

.desc {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  color: #8190b0; }

.production {
  position: relative;
  z-index: 1;
  margin-top: -120px;
  padding-bottom: 56px;
  @include d {
    margin-top: -140px; }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    @include d {
      top: 140px; }
    @include a {
      top: 150px; } }
  &:before {
    background: url('../img/bg-production.jpg') no-repeat 50% 50% / cover; }
  &:after {
    background: rgba($white, .7); }
  &__row {
    position: relative;
    display: flex;
    padding: 40px 5px 0;
    @include d {
      margin: 0 -15px;
      padding: 40px 0 0;
      border: none; }
    @include t {
      flex-direction: column;
      margin: 0; }
    @include s {
      padding-top: 20px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: $border;
      @include d {
        left: 15px;
        right: 15px; }
      @include t {
        left: 0;
        right: 0; } } }
  &__aside {
    flex: 0 0 calc(33% - 30px);
    width: calc(33% - 30px);
    margin: 0 15px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; } }
  &__container {
    flex: 0 0 calc(67% - 30px);
    width: calc(67% - 30px);
    margin: 0 15px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin: 0 0 20px;
      order: -1; }
    @include s {
      margin-bottom: 35px; } }
  &__aside &__info {
    @include t {
      display: none; } }
  &__container &__info {
    display: none;
    @include t {
      display: block; } }
  &__info {
    position: relative;
    margin-bottom: 90px;
    padding-left: 52px;
    font-size: 28px;
    font-weight: 400;
    color: $white;
    @include d {
      margin-bottom: 70px; }
    @include t {
      margin-bottom: 45px; }
    @include a {
      padding-left: 40px;
      font-size: 24px; }
    @include s {
      margin-bottom: 35px;
      font-size: 20px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 23px;
      height: 47px;
      background: url('../img/symbol.svg') no-repeat 0 0 / 23px 47px;
      @include a {
        width: 17px;
        height: 35px;
        background-size: 17px 35px; } } }
  &__content {
    margin-bottom: 40px; }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    @include a {
      margin: 0 -8px; }
    @include s {
      margin: 0; } }
  &__item {
    position: relative;
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 0 15px 17px;
    font-size: 0;
    overflow: hidden;
    @include a {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 0 8px 15px; }
    @include s {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
    &:before {
      z-index: 1;
      height: 3px;
      background-image: linear-gradient(to right, #ff3366, #e3097e); }
    &:after {
      top: 0;
      background-image: linear-gradient(198deg, rgba(42, 34, 66, 0.0), rgba(42, 34, 66, 0.9)); }
    &:not(:last-child) {
      @include s {
        margin-bottom: 25px; } } }
  &__item:hover &__pic {
    transform: scale(1.1); }
  &__pic {
    width: 100%;
    transition: all .5s; }
  &__name {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    z-index: 2;
    padding: 15px 10px 23px;
    border-top: 1px solid $white;
    font-size: 20px;
    font-weight: 400;
    color: $white;
    @include a {
      left: 10px;
      right: 10px;
      padding: 10px;
      font-size: 16px; }
    @include s {
      left: 25px;
      right: 25px;
      padding: 20px 0; } }
  &__btns {
    @include t {
      text-align: center; } }
  &__btn {
    width: 256px;
    height: 50px; } }

.head {
  position: relative;
  padding: 70px 0 23px;
  border-bottom: 1px solid $border;
  @include t {
    padding-top: 60px; }
  @include a {
    padding: 25px 0 15px; }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 23px;
    height: 47px;
    margin-left: -12px;
    background: url('../img/symbol.svg') no-repeat 0 0 / 23px 47px;
    @include a {
      left: 0;
      width: 17px;
      height: 37px;
      margin: 0;
      background-size: 17px 37px; } }
  &__row {
    display: flex;
    margin: 0 -42px;
    @include t {
      margin: 0 -20px; }
    @include a {
      display: block;
      margin: 0; } }
  &__col {
    flex: 0 0 calc(50% - 84px);
    width: calc(50% - 84px);
    margin: 0 42px;
    @include t {
      flex: 0 0 calc(50% - 40px);
      width: calc(50% - 40px);
      margin: 0 20px; }
    @include a {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:nth-child(2) {
      @include a {
        margin-top: 15px; } } }
  &__title {
    text-align: right;
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    @include d {
      font-size: 26px; }
    @include m {
      font-size: 22px; }
    @include a {
      padding-left: 30px;
      text-align: left;
      font-size: 20px; } }
  &__info {
    font-size: 16px;
    font-weight: 300;
    color: $border;
    @include a {
      font-size: 14px; }
    br {
      @include t {
        display: none; } } }
  &__font {
    white-space: nowrap; }
  &_white &__title {
    color: $white; } }

.advantages {
  position: relative;
  overflow: hidden;
  &__bg {
    position: absolute;
    top: -10%;
    right: 0;
    bottom: -40%;
    left: 0;
    background: url('../img/bg-advantages.jpg') no-repeat 50% 50% / cover;
    @include m {
      background: none; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(-28deg, rgba(255, 51, 102, 0), rgba(255, 51, 102, .5)), linear-gradient(0deg, rgba(42, 35, 66, .7), rgba(42, 35, 66, .7));
      opacity: .7;
      @include m {
        background-image: linear-gradient(142deg, #9E2B56, #2a2242);
        opacity: 1; } } }
  &__center {
    position: relative;
    z-index: 2; }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 45px 0 7px;
    @include s {
      padding: 35px 0 15px; } }
  &__item {
    flex: 0 0 (100%/3);
    width: (100%/3);
    margin-bottom: 60px;
    padding: 0 30px;
    text-align: center;
    @include t {
      margin-bottom: 45px;
      padding: 0 15px; }
    @include m {
      flex: 0 0 50%;
      width: 50%; }
    @include s {
      margin-bottom: 35px; } }
  &__preview {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 81px;
    margin-bottom: 20px;
    font-size: 0;
    @include m {
      margin-bottom: 15px; } }
  &__pic {
    transition: all .5s;
    &_1 {
      width: 83px; }
    &_2 {
      width: 70px; }
    &_3 {
      width: 97px; }
    &_4 {
      width: 70px; }
    &_5 {
      width: 55px; }
    &_6 {
      width: 92px; } }
  &__category {
    margin-bottom: 10px;
    font-size: 18px;
    color: $white;
    @include m {
      margin: 0; }
    @include s {
      font-size: 14px; } }
  &__text {
    max-width: 270px;
    margin: 0 auto;
    font-weight: 300;
    color: $border;
    @include m {
      display: none; }
    &_big {
      max-width: 310px; } } }

.partners {
  background: #f1f2f4;
  &__body {
    padding: 50px 0 70px;
    @include a {
      padding: 35px 0 45px; }
    @include s {
      margin: 0 -17px; } }
  &__list {
    padding: 0 50px; }
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 135px;
    margin-bottom: 11px;
    padding: 20px 0;
    font-size: 0; }
  &__item {
    display: block;
    padding: 15px 15px 18px;
    text-align: center;
    color: $black;
    transition: all .3s;
    &:hover {
      background: $white; } }
  &__name {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 400; }
  &__site {
    font-size: 14px;
    line-height: 1.2;
    color: #8190b0; }
  .owl-carousel .owl-item .partners__pic {
    max-width: 100%;
    max-height: 100%;
    &_rehau {
      width: 201px;
      margin-top: -30px; }
    &_lisec {
      width: 193px; }
    &_shurtz {
      width: 185px; }
    &_gu {
      width: 125px; }
    &_gg {
      width: 145px; } }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    background: $border;
    transition: all .3s;
    font-size: 0;
    .icon {
      font-size: 28px;
      fill: $white; }
    &:hover {
      background: $black; } }
  .owl-prev {
    left: 0; }
  .owl-next {
    right: 0; } }

.last {
  position: relative;
  overflow: hidden;
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -30%;
    background: linear-gradient(-28deg, rgba(255, 51, 102, 0), rgba(255, 51, 102, .5)), linear-gradient(0deg, rgba(42, 35, 66, .7), rgba(42, 35, 66, .7)), url('../img/bg-last.jpg') no-repeat center / cover;
    @include m {
      background-image: linear-gradient(142deg, #9E2B56, #2a2242); } }
  &__center {
    position: relative;
    z-index: 2; }
  &__head {
    padding-top: 50px; }
  &__body {
    padding: 50px 20px 70px 115px;
    @include d {
      padding: 50px 0 60px 0; }
    @include a {
      padding: 35px 0 40px; } }
  &__wrap {
    display: flex;
    margin: 0 -50px;
    @include d {
      margin: 0 -20px; }
    @include a {
      display: block;
      margin: 0; } }
  &__col {
    margin: 0 50px;
    @include d {
      margin: 0 20px; }
    @include a {
      margin: 0; }
    &:first-child {
      flex: 0 0 calc(46% - 100px);
      width: calc(46% - 100px);
      @include d {
        flex: 0 0 calc(46% - 40px);
        width: calc(46% - 40px); }
      @include a {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 40px; }
      @include s {
        margin-bottom: 25px; } }
    &:nth-child(2) {
      flex: 0 0 calc(54% - 100px);
      width: calc(54% - 100px);
      @include d {
        flex: 0 0 calc(54% - 40px);
        width: calc(54% - 40px); }
      @include a {
        flex: 0 0 100%;
        width: 100%; } } }
  &__article {
    display: block; }
  &__date {
    margin-bottom: 40px;
    @include a {
      margin-bottom: 20px; } }
  &__preview {
    margin-bottom: 30px;
    font-size: 0;
    @include s {
      margin: 0 -17px 20px; } }
  &__pic {
    width: 100%; }
  &__news {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    color: $white; }
  &__info {
    padding-right: 15px;
    font-size: 20px;
    font-weight: 300;
    color: $border;
    @include a {
      font-size: 16px; } }
  &__bg {
    padding: 30px 65px 38px 34px;
    background: rgba($white, .1);
    @include t {
      padding: 30px 20px; }
    @include s {
      padding: 0;
      background: none; } }
  &__list {
    margin-bottom: 50px;
    @include m {
      margin-bottom: 35px; }
    @include s {
      display: none; } }
  &__item {
    display: flex;
    @include m {
      display: block; }
    &:not(:last-child) {
      margin-bottom: 25px;
      @include m {
        margin-bottom: 20px; } } }
  &__time {
    flex: 0 0 90px;
    width: 90px;
    @include m {
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 10px; } }
  &__box {
    flex: 0 0 calc(100% - 90px);
    width: calc(100% - 90px);
    @include m {
      flex: 0 0 100%;
      width: 100%; } }
  &__day {
    font-size: 28px;
    line-height: 1.2;
    color: $white;
    @include m {
      margin-right: 5px;
      font-size: 20px; } }
  &__month {
    margin-bottom: 6px;
    line-height: 1.2;
    color: $white;
    @include m {
      margin: 0 5px 0 0; } }
  &__year {
    line-height: 1.2;
    color: $border;
    @include m {
      padding-top: 2px; } }
  &__title {
    margin-bottom: 10px; }
  &__text {
    font-weight: 300;
    color: $border;
    @include m {
      font-size: 15px; } }
  &__btns {
    padding-left: 25px;
    text-align: center;
    @include t {
      padding-left: 0; } } }

.date {
  display: inline-block;
  border-bottom: 3px solid $pink;
  margin-bottom: 40px;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 1.2;
  color: $white; }

.about {
  background: #f1f2f4;
  @include t {
    padding-bottom: 50px; }
  @include a {
    padding-bottom: 40px; }
  &__row {
    display: flex;
    padding: 40px 20px 70px;
    @include t {
      position: relative;
      flex-direction: column;
      padding: 40px 0 80px; }
    @include a {
      padding: 30px 0 80px; } }
  &__col {
    &:first-child {
      flex: 0 0 54%;
      width: 54%;
      padding-right: 60px;
      @include t {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 40px;
        padding-right: 0; }
      @include a {
        margin-bottom: 20px; } }
    &:nth-child(2) {
      flex: 0 0 46%;
      width: 46%;
      @include t {
        flex: 0 0 100%;
        width: 100%; } } }
  &__content br {
    @include a {
      display: none; } }
  &__content:not(:last-child) {
    margin-bottom: 50px;
    @include t {
      margin: 0; } }
  &__content blockquote {
    padding-left: 18px;
    font-size: 20px;
    &:not(:last-child) {
      margin-bottom: 25px; } }
  &__gallery_sm {
    padding-right: 95px;
    @include d {
      padding-right: 0; } }
  &__gallery_sm &__preview {
    &:not(:last-child) {
      margin-bottom: 20px; } }
  &__preview {
    font-size: 0;
    &:not(:last-child) {
      margin-bottom: 35px;
      @include a {
        margin-bottom: 20px; } } }
  &__pic {
    width: 100%; }
  &__btn {
    width: 289px;
    height: 49px;
    @include t {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%); } }
  &_white {
    background: $white; }
  &_white &__row {
    @include t {
      padding-bottom: 0; } } }

.order {
  background: linear-gradient(to left top, rgba(255, 51, 102, 0) 0%, rgba(255, 51, 102, .4) 100%),#2A2242;
  &__form {
    display: flex;
    align-items: flex-end;
    margin: 0 -16px;
    padding: 85px 100px 95px;
    @include d {
      padding: 70px 30px; }
    @include t {
      margin: 0;
      padding: 60px 0; }
    @include m {
      display: block;
      max-width: 400px;
      margin: 0 auto;
      padding: 45px 0 55px; }
    @include a {
      padding: 25px 0 45px; } }
  &__col {
    margin: 0 16px;
    @include t {
      margin: 0 10px; }
    @include m {
      margin: 0; }
    &:first-child {
      flex: 0 0 calc(38% - 32px);
      width: calc(38% - 32px);
      @include t {
        flex: 0 0 calc(40% - 20px);
        width: calc(40% - 20px); }
      @include m {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 30px; } }
    &:nth-child(2),
    &:nth-child(3) {
      flex: 0 0 calc(30% - 32px);
      width: calc(30% - 32px);
      @include m {
        flex: 0 0 100%;
        width: 100%; } }
    &:nth-child(2) {
      @include t {
        flex: 0 0 calc(38% - 20px);
        width: calc(38% - 20px); }
      @include m {
        margin-bottom: 40px; } }
    &:nth-child(3) {
      @include t {
        flex: 0 0 calc(24% - 20px);
        width: calc(24% - 20px); } } }
  &__field .field__label {
    margin-bottom: 14px;
    font-size: 20px;
    line-height: 1.2;
    color: $white;
    @include a {
      margin-bottom: 8px; } }
  &__field .field__input {
    height: 48px;
    border-width: 2px;
    background: none;
    font-size: 20px;
    color: $white;
    @include placeholder {
      color: $white; } }
  &__btn {
    height: 48px;
    width: 100%;
    border: 2px solid $pink;
    background: rgba($pink, .7);
    &:hover {
      background: $pink; } } }

.footer {
  background: #f1f2f4;
  &__body {
    padding-bottom: 45px;
    @include t {
      padding-bottom: 15px; }
    @include a {
      padding: 20px 0 0; }
    @include s {
      padding: 20px 0 30px; } }
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 37px;
    @include a {
      margin-bottom: 25px;
      padding-bottom: 10px;
      border-bottom: 1px solid $border; } }
  &__logo {
    flex: 0 0 115px;
    width: 115px;
    padding-right: 25px;
    font-size: 0;
    @include a {
      flex: 0 0 90px;
      width: 90px;
      padding-right: 20px; } }
  &__wrap {
    display: flex;
    align-items: center;
    flex: 0 0 calc(100% - 115px);
    width: calc(100% - 115px);
    height: 82px;
    padding: 10px 0 5px;
    border-bottom: 1px solid $border;
    @include t {
      display: block; }
    @include a {
      display: flex;
      align-items: center;
      flex: 0 0 calc(100% - 90px);
      width: calc(100% - 90px);
      height: 64px;
      padding: 5px 0;
      border: none; } }
  &__logo &__pic {
    width: 100%; }
  &__info {
    margin-right: auto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    color: #8190b0;
    @include t {
      margin-bottom: 20px; }
    @include a {
      margin: 0; }
    br {
      @include t {
        display: none; } } }
  &__nav {
    display: flex;
    @include a {
      display: none; } }
  &__box {
    position: relative;
    padding: 0 10px;
    @include m {
      padding: 0; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -5px;
      height: 12px;
      width: 1px;
      background: $border;
      @include m {
        display: none; } }
    &:last-child {
      padding-right: 0;
      &:before {
        display: none; } } }
  &__nav &__link {
    padding: 8px 20px 7px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    color: $black;
    transition: all .3s;
    @include d {
      padding: 8px 10px 7px; }
    @include m {
      padding: 8px 10px 7px; }
    &:hover {
      background: #8190B0;
      color: $white; } }
  &__row {
    display: flex;
    padding: 0 20px;
    @include t {
      flex-wrap: wrap; }
    @include s {
      padding: 0 40px; } }
  &__col {
    flex: 0 0 25%;
    width: 25%;
    padding-right: 20px;
    @include t {
      flex: 0 0 33.33%;
      width: 33.33%;
      margin-bottom: 30px;
      padding-right: 20px; }
    @include a {
      flex: 0 0 50%;
      width: 50%; }
    @include s {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 20px; }
    &:last-child {
      @include t {
        display: flex;
        flex: 0 0 100%;
        width: 100%;
        align-items: center; }
      @include a {
        display: block;
        flex: 0 0 50%;
        width: 50%; }
      @include s {
        flex: 0 0 100%;
        width: 100%;
        margin: 0; } } }
  &__col:last-child &__category {
    @include t {
      margin: 0 30px 0 0; }
    @include a {
      margin: 0 0 15px 0; } }
  &__category {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    @include a {
      margin-bottom: 8px; } }
  &__desc {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 300;
    color: $border;
    @include s {
      margin-bottom: 3px; } }
  &__text {
    font-size: 14px;
    font-weight: 300;
    color: $black; }
  &__item:not(:last-child) {
    margin-bottom: 16px;
    @include a {
      margin-bottom: 8px; } }
  &__line {
    display: flex; }
  &__line &__desc {
    flex: 0 0 70px;
    width: 70px;
    padding-right: 10px; }
  &__control {
    padding: 5px 0;
    background: rgba($border, .3);
    @include a {
      display: none; } }
  &__control &__center {
    display: flex;
    align-items: center;
    max-width: 1190px; }
  &__menu {
    display: flex;
    margin: 0 auto 0 -14px; }
  &__menu &__link {
    padding: 8px 14px;
    font-size: 14px;
    color: $border;
    transition: all .3s;
    &:hover {
      background: #8190B0;
      color: $white; } }
  &__counter {
    font-size: 0; }
  &__bottom {
    padding: 6px 0;
    background: #586377;
    @include a {
      padding: 8px 0; } }
  &__bottom &__center {
    display: flex;
    align-items: center;
    max-width: 1190px;
    @include a {
      flex-direction: column; } }
  &__copyright {
    margin-right: auto;
    font-size: 10px;
    color: #f1f2f4;
    @include a {
      margin: 0 0 10px 0; } }
  &__author {
    display: flex;
    align-items: center; }
  &__author &__stat {
    margin-right: 11px;
    font-size: 10px;
    color: #f1f2f4;
    @include a {
      max-width: 125px; } }
  &__preview {
    font-size: 0; }
  &__author &__pic {
    width: 82px; } }

.section {
  position: relative;
  &__head {
    position: relative;
    z-index: 3;
    padding-top: 220px;
    background: url('../img/bg-sect-head.jpg') no-repeat 50% 0 / cover;
    @include t {
      padding-top: 180px; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    &:before {
      z-index: 2;
      opacity: 0.3;
      -webkit-backdrop-filter: blur(44px);
      backdrop-filter: blur(44px);
      background-image: linear-gradient(120deg, rgba(255, 51, 102, 0.0), #ff3366); }
    &:after {
      z-index: 1;
      background: rgba($black, .8); } }
  &__head &__center {
    position: relative;
    z-index: 3; }
  &__wrap {
    display: flex;
    margin: 0 -35px;
    padding: 40px 20px 70px;
    @include t {
      display: block;
      margin: 0;
      padding-bottom: 60px; }
    @include a {
      padding: 20px 0 30px; }
    &_pb50 {
      padding-bottom: 50px; }
    &_pb100 {
      padding-bottom: 100px;
      @include t {
        padding-bottom: 100px; }
      @include a {
        padding-bottom: 85px; } }
    &_pb120 {
      padding-bottom: 120px;
      @include d {
        padding-bottom: 110px; }
      @include t {
        padding-bottom: 110px; }
      @include a {
        padding-bottom: 90px; } }
    &_pb135 {
      padding-bottom: 135px;
      @include d {
        padding-bottom: 120px; }
      @include t {
        padding-bottom: 110px; }
      @include a {
        padding-bottom: 100px; } } }
  &__body {
    position: relative;
    z-index: 4;
    &_50 {
      margin-top: -50px; }
    &_70 {
      margin-top: -70px; }
    &_mobile {
      @include a {
        margin-top: -50px; } } }
  &__body_50 .article__aside {
    padding-top: 80px;
    @include t {
      padding: 0; } }
  &__col {
    flex: 0 0 calc(50% - 70px);
    width: calc(50% - 70px);
    margin: 0 35px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:first-child {
      @include t {
        margin-bottom: 30px; }
      @include a {
        margin-bottom: 20px; } } }
  &__title {
    position: relative;
    padding-left: 45px;
    font-size: 36px;
    font-weight: 400;
    color: $white;
    @include t {
      font-size: 32px; }
    @include m {
      font-size: 28px; }
    @include a {
      padding-left: 28px;
      font-size: 24px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 23px;
      height: 47px;
      background: url('../img/symbol.svg') no-repeat 0 0 / 23px 47px;
      @include a {
        width: 17px;
        height: 37px;
        background-size: 17px 37px; } } }
  &__info {
    font-size: 20px;
    font-weight: 300;
    color: $border;
    @include a {
      font-size: 14px; } } }

.breadcrumbs {
  display: flex;
  padding: 0 20px 20px;
  border-bottom: 1px solid $border;
  @include a {
    flex-wrap: wrap;
    padding: 0 0 15px; }
  &__item {
    position: relative;
    padding-right: 16px;
    font-size: 10px;
    color: $border;
    @include a {
      margin: 0 10px 5px 0; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 6px;
      height: 10px;
      margin-top: -5px;
      background: url('../img/arrow-right-grey.svg') no-repeat 0 0 / 6px 10px; }
    &:not(:last-child) {
      margin-right: 10px; }
    &:last-child:before {
      display: none; } }
  &__link {
    color: $border;
    transition: all .3s;
    &:hover {
      color: $white; } } }

.diplom {
  &__wrap {
    padding: 65px 0 110px;
    @include d {
      padding-bottom: 90px; }
    @include t {
      padding: 50px 0 60px; }
    @include a {
      padding: 35px 0 40px; }
    @include s {
      margin: 0 -17px; } }
  &__list {
    padding: 0 40px; }
  &__preview {
    display: block;
    font-size: 0;
    overflow: hidden; }
  &__preview:hover &__pic {
    transform: scale(1.1); }
  &__pic {
    transition: all .5s; }
  .owl-item {
    padding: 0 10px;
    @include m {
      padding: 0 5px; } }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    background: $border;
    transition: all .3s;
    font-size: 0;
    .icon {
      font-size: 28px;
      fill: $white; }
    &:hover {
      background: $black; } }
  .owl-prev {
    left: 0; }
  .owl-next {
    right: 0; } }

.objects {
  padding-bottom: 100px;
  @include t {
    padding-bottom: 70px; }
  @include a {
    padding-bottom: 45px; }
  &__aside {
    padding-left: 30px;
    @include d {
      padding-left: 0; }
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
      order: -1; } }
  &__container {
    max-width: calc(100% - 358px);
    @include t {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      padding: 0; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding: 0 5px;
    @include t {
      margin-bottom: 30px; }
    @include a {
      margin: 0 -8px 30px;
      padding: 0; }
    @include s {
      margin: 0 0 40px; } }
  &__box {
    flex: 0 0 calc((100%/3) - 30px);
    width: calc((100%/3) - 30px);
    margin: 0 15px 20px;
    @include t {
      flex: 0 0 calc((50% - 30px);
      width: calc((50% - 30px); }
    @include a {
      flex: 0 0 calc((50% - 16px);
      width: calc((50% - 16px);
      margin: 0 8px 16px; }
    @include s {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:not(:last-child) {
      @include s {
        margin-bottom: 20px; } } }
  &__content {
    padding: 0 20px;
    @include a {
      padding: 0; } }
  &__aside &__content {
    padding: 0; }
  &__aside &__content h2 {
    margin-bottom: 20px; }
  &__in {
    margin-bottom: 50px;
    padding: 0 20px 20px;
    border-bottom: 1px solid $border;
    @include a {
      margin-bottom: 35px;
      padding: 0 0 20px; } }
  &__row {
    display: flex;
    @include t {
      flex-wrap: wrap; }
    &:not(:last-child) {
      margin-bottom: 50px;
      @include a {
        margin-bottom: 30px; } } }
  &__video {
    margin-bottom: 40px;
    font-size: 0;
    @include s {
      margin: 0 -17px 30px; }
    iframe {
      width: 100%;
      @include a {
        height: 280px; }
      @include s {
        height: 230px; } } }
  &__date {
    margin-bottom: 90px;
    @include d {
      margin-bottom: 70px; }
    @include a {
      margin-bottom: 50px; } }
  &__foot {
    padding: 0 20px;
    @include a {
      padding: 0; }
    @include s {
      position: relative;
      padding-bottom: 50px; } }
  &__foot &__btn {
    @include s {
      position: absolute;
      left: 0;
      bottom: 0; } }
  &__foot &__box {
    margin-bottom: 0;
    @include t {
      margin-bottom: 30px; }
    @include a {
      margin-bottom: 16px; } }
  &__line {
    display: flex;
    margin: 0 -15px;
    @include t {
      flex-wrap: wrap; }
    @include a {
      margin: 0 -8px; }
    @include s {
      margin: 0; } }
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @include a {
      margin-bottom: 20px; } }
  &__top &__info {
    margin: 0 auto 0 0;
    font-size: 28px;
    @include a {
      font-size: 24px; } }
  &__photo {
    @include s {
      margin: 0 -17px; } }
  &_pb75 {
    padding-bottom: 75px;
    @include t {
      padding-bottom: 30px; }
    @include a {
      padding-bottom: 20px; }
    @include s {
      padding-bottom: 40px; } }
  &_desc {
    padding-bottom: 70px;
    @include t {
      padding-bottom: 60px; }
    @include m {
      padding-bottom: 40px; } }
  &_desc &__box .box__wrap {
    height: 100px;
    @include d {
      height: 80px; } }
  &_desc &__box .box__title {
    height: 52px; }
  &_desc &__list {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border; }
  &_window {
    padding-bottom: 70px;
    @include t {
      padding-bottom: 50px; }
    @include m {
      padding-bottom: 40px; } }
  &_window &__list {
    margin-bottom: 15px; }
  &_window &__box {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin-bottom: 40px;
    @include m {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 0 10px 20px; }
    @include s {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:not(:last-child) {
      @include s {
        margin-bottom: 25px; } } }
  &_window &__box .box__wrap {
    height: 90px;
    @include t {
      height: 70px; } }
  &_window &__box .box__title {
    @include t {
      height: 40px; } }
  &_window &__box .box__preview {
    overflow: hidden; } }

.share {
  display: flex;
  align-items: center;
  @include a {
    display: block; }
  &__title {
    margin-right: 60px;
    @include t {
      margin-right: 40px; }
    @include a {
      margin: 0 0 20px 0; } } }

.aside {
  flex: 0 0 358px;
  width: 358px; }

.container {
  flex: 0 0 calc(100% - 358px);
  width: calc(100% - 358px);
  padding-right: 30px; }

.photo {
  &__big {
    margin-bottom: 20px;
    font-size: 0; }
  &__pic {
    width: 100%; }
  &__slider {
    padding: 0 25px; }
  &__item {
    position: relative;
    font-size: 0;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      cursor: pointer;
      transition: all .2s; }
    &:hover:before {
      box-shadow: inset 0 0 0 2px $pink; } }
  .owl-item {
    padding: 0 5px; }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25px;
    background: $border;
    transition: all .3s;
    font-size: 0;
    .icon {
      font-size: 14px;
      fill: $white; }
    &:hover {
      background: $black; } }
  .owl-prev {
    left: 0; }
  .owl-next {
    right: 0; } }

.box {
  display: block;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    height: 4px;
    background-image: linear-gradient(to right, #ff3366, #e3097e); }
  &__preview {
    font-size: 0; }
  &__pic {
    width: 100%;
    transition: all .5s; }
  &__wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 148px;
    padding: 15px 25px;
    overflow: hidden;
    background-image: linear-gradient(235deg, rgba(42, 34, 66, 0.4), rgba(42, 34, 66, 0.9));
    transition: all .4s;
    @include d {
      height: 120px;
      padding: 10px 20px; }
    @include a {
      height: 100px; } }
  &__desc {
    padding: 10px 8px 0;
    border-top: 1px solid $white;
    @include a {
      padding: 10px 0 0; } }
  &__title {
    position: relative;
    height: 78px;
    margin-bottom: 7px;
    padding-right: 20px;
    overflow: hidden;
    font-size: 20px;
    font-weight: 400;
    color: $white;
    @include d {
      height: 60px;
      font-size: 16px; }
    @include t {
      padding-right: 0; }
    @include a {
      height: 48px;
      font-size: 14px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 14px;
      height: 30px;
      background: url('../img/symbol.svg') no-repeat 0 0 / 14px 30px;
      visibility: hidden;
      opacity: 0;
      transition: all .3s; } }
  &__text {
    display: none;
    height: 72px;
    margin-bottom: 25px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 300;
    color: $white;
    @include d {
      height: 54px; }
 }    // transition: all .3s .2s
  &__date {
    padding-right: 125px;
    font-size: 14px;
    color: $border;
    @include d {
      font-size: 13px; }
    @include t {
      padding: 0; } }
  &__btn {
    position: absolute;
    right: 30px;
    bottom: 18px;
    width: 114px;
    height: 33px;
    border: 1px solid $white;
    @include f;
    font-size: 12px;
    color: $white;
    visibility: hidden;
    opacity: 0;
    transition: all .1s .2s;
    @include d {
      bottom: 15px; } } }

body:not(.touch-device) .box:hover .box__wrap {
  height: 100%;
  padding-top: 25px;
  @include d {
    padding-top: 15px; } }

body:not(.touch-device) .box:hover .box__title:before {
  visibility: visible;
  opacity: 1; }

body:not(.touch-device) .box:hover .box__text {
  display: block; }

body:not(.touch-device) .box:hover .box__btn {
  visibility: visible;
  opacity: 1; }

body:not(.touch-device) .objects_window .box:hover .box__wrap {
  height: 90px;
  padding-top: 10px; }

body:not(.touch-device) .objects_window .box:hover .box__title:before {
  visibility: hidden;
  opacity: 0; }

body:not(.touch-device) .objects_window .box:hover .box__pic {
  transform: scale(1.2); }

.news {
  padding-bottom: 70px;
  @include a {
    padding-bottom: 45px; }
  &__container {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    @include a {
      margin-bottom: 35px;
      padding: 0; } }
  &__item {
    flex: 0 0 calc((100%/3) - 30px);
    width: calc((100%/3) - 30px);
    margin: 0 15px 45px;
    color: $black;
    @include d {
      flex: 0 0 calc((50% - 30px);
      width: calc((50% - 30px);
      margin: 0 15px 45px; }
    @include t {
      flex: 0 0 calc((50% - 20px);
      width: calc((50% - 20px);
      margin: 0 10px 45px; }
    @include a {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:not(:last-child) {
      @include a {
        margin-bottom: 25px; } } }
  &__item:hover &__pic {
    transform: scale(1.1); }
  &__item:hover &__line {
    border-color: $pink; }
  &__preview {
    margin-bottom: 11px;
    overflow: hidden;
    font-size: 0; }
  &__pic {
    width: 100%;
    transition: all .5s; }
  &__line {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid $border;
    transition: all .3s;
    @include s {
      display: block; } }
  &__date {
    flex: 0 0 100px;
    width: 100px;
    padding: 0 10px;
    @include s {
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 10px;
      padding: 0; } }
  &__wrap {
    flex: 0 0 calc(100% - 100px);
    width: calc(100% - 100px);
    @include s {
      flex: 0 0 100%;
      width: 100%; } }
  &__day {
    font-size: 24px;
    @include s {
      margin-right: 5px; } }
  &__month {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.45;
    @include a {
      margin-bottom: 5px; }
    @include s {
      margin: 0 5px 0 0; } }
  &__year {
    font-weight: 300;
    @include s {
      padding-top: 2px; } }
  &__title {
    height: 46px;
    margin-bottom: 10px;
    overflow: hidden;
    @include m {
      height: 40px;
      font-size: 16px; } }
  &__desc {
    height: 60px;
    font-weight: 300;
    overflow: hidden;
    @include m {
      height: 54px;
      font-size: 14px; }
    @include a {
      height: 48px; } } }

.pagination {
  display: flex;
  justify-content: center;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    padding-top: 2px;
    border: solid 1px #8190b0;
    font-size: 16px;
    line-height: 1;
    color: $black;
    transition: all .3s;
    @include a {
      margin-right: 8px; }
    @include s {
      flex: 0 0 25px;
      width: 25px;
      height: 30px;
      margin-right: 5px; }
    &:hover {
      border-color: lighten(#8190B0, 20);
      background: lighten(#8190B0, 20); }
    &.active {
      background: #8190B0;
      color: $white; }
    &_arrow {
      border: none;
      background: $black;
      &:hover {
        background: lighten($black, 15); } }
    &:first-child {
      margin-right: 30px;
      @include a {
        margin-right: 20px; }
      @include s {
        margin-right: 10px; } }
    &:last-child {
      margin: 0 0 0 15px;
      @include a {
        margin-left: 12px; }
      @include s {
        margin-left: 5px; } } }
  &__item_arrow .icon {
    font-size: 16px;
    fill: $white; } }

.contacts {
  &__body {
    padding: 40px 115px 70px;
    @include d {
      padding: 40px 20px 50px; }
    @include a {
      padding: 35px 0 40px; } }
  &__row {
    display: flex;
    @include t {
      margin: 0 -15px; }
    @include a {
      display: block;
      margin: 0; } }
  &__col {
    @include t {
      margin: 0 15px; }
    @include a {
      margin: 0; }
    &:first-child {
      flex: 0 0 calc(100% - 425px);
      width: calc(100% - 425px);
      @include t {
        flex: 0 0 calc(50% - 30px);
        width: calc(50% - 30px); }
      @include a {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 30px; } }
    &:nth-child(2) {
      flex: 0 0 425px;
      width: 425px;
      @include t {
        flex: 0 0 calc(50% - 30px);
        width: calc(50% - 30px); }
      @include a {
        flex: 0 0 100%;
        width: 100%; } } }
  &__item:not(:last-child) {
    margin-bottom: 40px;
    @include m {
      margin-bottom: 30px; } }
  &__title {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 15px;
      font-size: 20px; }
    &_mb30 {
      margin-bottom: 30px;
      @include m {
        margin-bottom: 20px; } } }
  &__box {
    padding-left: 23px;
    @include m {
      padding-left: 0; }
    &:not(:last-child) {
      margin-bottom: 20px; } }
  &__desc {
    font-weight: 300;
    @include m {
      font-size: 14px; } }
  &__box &__desc {
    margin-bottom: 8px; }
  &__text {
    font-size: 20px;
    line-height: 1.05;
    color: $black;
    @include t {
      font-size: 18px; }
    @include m {
      font-size: 16px; } }
  &__address {
    margin-bottom: 5px; }
  &__line {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 7px; } }
  &__link {
    margin-right: 22px;
    font-size: 20px;
    line-height: 1.2;
    color: $black;
    transition: all .3s;
    @include t {
      font-size: 17px; }
    @include m {
      margin-right: 10px;
      font-size: 14px; }
    &:hover {
      color: $border; } }
  &__schedule {
    padding-left: 23px;
    @include m {
      padding-left: 0; } }
  &__schedule &__text {
    flex: 0 0 160px;
    @include m {
      flex: 0 0 117px; } }
  &__social {
    padding-left: 23px;
    @include m {
      padding-left: 0; } }
  &__form {
    padding-left: 23px;
    @include m {
      padding-left: 0; } }
  &__fieldset {
    margin-bottom: 40px; }
  &__field:not(:last-child) {
    margin-bottom: 30px; }
  &__field .field__wrap {
    font-size: 0; }
  &__field .field__label {
    font-weight: 300; }
  &__btn {
    width: 252px;
    height: 40px;
    @include m {
      width: 100%; } } }

.consult {
  position: relative;
  background: url('../img/bg-consult.jpg') no-repeat 50% 50% / cover;
  @include a {
    background: #F1F2F4; }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba($white, .5); }
  &__center {
    position: relative;
    z-index: 2; }
  &__wrap {
    display: flex;
    padding: 40px 5px 70px;
    @include t {
      flex-wrap: wrap;
      padding: 40px 5px 30px; }
    @include a {
      padding: 35px 0 40px; } }
  &__item {
    position: relative;
    flex: 0 0 calc(25% - 30px);
    width: calc(25% - 30px);
    margin: 0 15px;
    padding: 0 20px 30px;
    @include d {
      flex: 0 0 calc(25% - 16px);
      width: calc(25% - 16px);
      margin: 0 8px;
      padding: 0 10px 20px; }
    @include t {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin-bottom: 30px; }
    @include a {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:not(:last-child) {
      @include a {
        margin-bottom: 25px; } }
    &:before {
      content: "";
      position: absolute;
      top: 85px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: $white; } }
  &__top {
    margin-bottom: 6px;
    padding-right: 40px; }
  &__preview {
    display: inline-flex;
    width: 104px;
    height: 104px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 2px solid $white;
    font-size: 0; }
  &__pic {
    width: 100px;
    min-height: 100%; }
  &__man {
    margin-bottom: 10px;
    font-size: 18px; }
  &__post {
    height: 36px;
    overflow: hidden;
    font-size: 14px;
    color: $border; }
  &__bot {
    padding-top: 20px;
    border-top: 1px solid $border; }
  &__text {
    white-space: nowrap;
    transition: all .3s; }
  &__text_mail {
    text-decoration: underline; }
  &__line {
    display: inline-flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 6px; } }
  &__icon {
    flex: 0 0 18px;
    margin-right: 18px;
    text-align: center;
    font-size: 0;
    .icon {
      fill: $border;
      transition: all .3s; }
    .icon-mobile {
      font-size: 19px; }
    .icon-phone {
      font-size: 16px; }
    .icon-mail {
      font-size: 17px; }
    .icon-skype {
      font-size: 18px; } }
  &__phones {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px; }
  &__phones &__text {
    font-size: 18px;
    line-height: 1;
    color: $black; }
  &__phones &__line:hover &__text {
    color: $border; }
  &__other {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  &__other &__line:hover .icon {
    fill: $black; }
  &__other &__line:hover &__text {
    text-decoration: none;
    color: $black; }
  &__other &__text {
    font-size: 14px;
    color: #8190B0; } }

.map {
  padding: 70px 0;
  @include t {
    padding: 50px 0; }
  @include a {
    padding: 0 0 40px; }
  &__center {
    max-width: 1190px;
    @include a {
      padding: 0; } }
  &__box {
    width: 100%;
    height: 595px;
    @include t {
      height: 450px; }
    @include m {
      height: 350px; }
    @include a {
      height: 270px; } } }

.article {
  padding-bottom: 80px;
  @include t {
    padding-bottom: 60px; }
  @include a {
    padding-bottom: 40px; }
  &__row {
    display: flex;
    padding: 0 20px;
    @include t {
      flex-wrap: wrap; }
    @include a {
      padding: 0; } }
  &__content blockquote:not(:last-child) {
    margin-bottom: 40px; }
  &__container {
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 40px;
      padding: 0; } }
  &__aside {
    padding-top: 110px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      padding: 0; } }
  &__info {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 400;
    @include a {
      font-size: 24px; } }
  &__list {
    margin-bottom: 45px;
    @include t {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px 30px; }
    @include a {
      margin: 0 -8px 10px; }
    @include s {
      margin: 0 0 25px; } }
  &__list &__box {
    @include t {
      flex: 0 0 calc(50% - 30px);
      width: calc(50% - 30px);
      margin: 0 15px 15px; }
    @include a {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 0 8px 16px; }
    @include s {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; } }
  &__box:not(:last-child) {
    margin-bottom: 23px;
    @include s {
      margin-bottom: 20px; } }
  &__btns {
    text-align: right;
    @include t {
      text-align: left; } } }

.window {
  padding-bottom: 70px;
  @include t {
    padding-bottom: 55px; }
  &__row {
    display: flex;
    padding: 0 20px;
    @include t {
      display: block; }
    @include a {
      padding: 0; } }
  &__aside,
  &__container {
    @include t {
      flex: 0 0 100%;
      width: 100%;
      padding: 0; } }
  &__container {
    max-width: calc(100% - 358px);
    @include t {
      max-width: 100%;
      padding-bottom: 30px; } }
  &__content {
    margin-bottom: 40px; }
  &__content h2 {
    margin-bottom: 20px; }
  &__photo {
    margin-bottom: 85px;
    @include d {
      margin-bottom: 65px; }
    @include t {
      margin-bottom: 50px; }
    @include a {
      margin-bottom: 40px; }
    @include s {
      margin: 0 -17px 30px; } }
  &__photo .photo__big {
    border: 1px solid #f1f2f4; }
  &__video {
    margin-bottom: 50px;
    font-size: 0;
    @include a {
      margin-bottom: 30px; }
    @include s {
      margin: 0 -17px 30px; } }
  &__video iframe {
    width: 100%;
    @include m {
      height: 300px; }
    @include a {
      height: 250px; } }
  &__control {
    margin-bottom: 50px;
    padding: 20px 35px 12px;
    border-width: 3px 1px;
    border-style: solid;
    border-color: $pink #f1f2f4 #7f7a8e;
    background: $white;
    @include a {
      margin-bottom: 35px; } }
  &__title {
    position: relative;
    padding-left: 25px;
    font-size: 24px;
    font-weight: 400;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 30px;
      background: url('../img/symbol.svg') no-repeat 0 0 / 14px 30px; } }
  &__control &__title {
    margin-bottom: 15px;
    padding: 0 0 15px 25px;
    border-bottom: 1px solid $black; }
  &__menu {
    padding-left: 25px;
    @include a {
      padding-left: 0; } }
  &__link {
    display: flex;
    align-items: center;
    min-height: 45px;
    font-size: 16px;
    font-weight: 300;
    color: $black;
    transition: all .3s;
    &:hover {
      color: $border; }
    &.active {
      font-size: 20px;
      font-weight: 700;
      color: $black; }
    &:not(:last-child) {
      border-bottom: 1px solid $border; } }
  &__head {
    padding: 19px 35px;
    background: $black;
    @include a {
      padding: 15px 25px; } }
  &__form &__title {
    color: $white;
    @include a {
      font-size: 20px; } }
  &__body {
    padding: 30px 35px 33px;
    border-bottom: 3px solid #7F7A8E;
    background-color: #f1f2f4;
    @include a {
      padding: 20px 15px; } }
  &__field {
    margin-bottom: 20px; }
  &__field .field__textarea {
    height: 100px; }
  &__btn {
    width: 100%;
    height: 40px; } }

.action {
  padding-bottom: 70px;
  @include t {
    padding-bottom: 60px; }
  @include m {
    padding-bottom: 45px; }
  &__list {
    margin-bottom: 50px;
    @include m {
      margin-bottom: 35px; } }
  &__item {
    display: flex;
    align-items: center;
    background: $white;
    border: solid 1px #f1f2f4;
    transition: all .3s;
    @include a {
      display: block; }
    &:hover {
      box-shadow: 0 3px 25px rgba($border, .35); }
    &:not(:last-child) {
      margin-bottom: 40px;
      @include t {
        margin-bottom: 30px; } } }
  &__preview {
    flex: 0 0 40%;
    width: 40%;
    font-size: 0;
    @include d {
      flex: 0 0 45%;
      width: 45%; }
    @include a {
      flex: 0 0 100%;
      width: 100%; } }
  &__pic {
    width: 100%; }
  &__wrap {
    padding: 10px 20px 5px 30px;
    @include a {
      padding: 15px; } }
  &__date {
    margin-bottom: 15px;
    color: $border; }
  &__title {
    margin-bottom: 15px;
    color: $black;
    @include d {
      height: 42px;
      margin-bottom: 10px;
      overflow: hidden; }
    @include t {
      margin-bottom: 20px; } }
  &__text {
    height: 62px;
    overflow: hidden;
    margin-bottom: 25px;
    color: $border;
    @include d {
      height: 54px;
      margin-bottom: 15px;
      font-size: 14px; }
    @include t {
      display: none; }
    @include a {
      display: block;
      height: 48px; } } }

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  min-width: 320px;
  padding: 20px;
  background-color: rgba($black, .8);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  z-index: 999;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  &.visible {
    pointer-events: all;
    visibility: visible;
    opacity: 1; }
  &.animation &__wrap {
    opacity: 1;
    transform: scale(1); }
  &__wrap {
    position: relative;
    max-width: 380px;
    width: 100%;
    padding: 30px 20px;
    margin: auto;
    border-bottom: 3px solid $pink;
    opacity: 0;
    transform: scale(.8);
    transition: opacity .7s, transform .7s;
    background-color: $white;
    text-align: center; }
  &__title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    @include m {
      font-size: 22px; } }
  &__text {
    font-size: 20px;
    @include m {
      font-size: 16px; } }
  &__close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -30px;
    right: -30px;
    font-size: 0;
    @include m {
      right: 0; }
    .icon {
      font-size: 20px;
      fill: $white;
      transition: fill .3s; }
    &:hover .icon {
      fill: $pink; } } }


