@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&subset=cyrillic");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.icon-arrow-down {
  width: 1.84em;
  height: 1em;
  fill: initial;
}

.icon-arrow-next {
  width: 0.54em;
  height: 1em;
  fill: initial;
}

.icon-arrow-prev {
  width: 0.54em;
  height: 1em;
  fill: initial;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-mail {
  width: 1.02em;
  height: 1em;
  fill: initial;
}

.icon-mobile {
  width: 0.6em;
  height: 1em;
  fill: initial;
}

.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-skype {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-youtube {
  width: 1.41em;
  height: 1em;
  fill: initial;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s,visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s,visibility 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide:before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px 6px 0;
}

.fancybox-slide--iframe {
  padding: 44px 44px 0;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image, .fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px;
}

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px;
}

.fancybox-iframe, .fancybox-video {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.fancybox-iframe {
  vertical-align: top;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:focus, .fancybox-button:hover {
  color: #fff;
}

.fancybox-button.disabled, .fancybox-button.disabled:hover, .fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
}

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3;
}

.fancybox-button--pause svg path:nth-child(1), .fancybox-button--play svg path:nth-child(2) {
  display: none;
}

.fancybox-button--play svg path, .fancybox-button--share svg path, .fancybox-button--thumbs svg path {
  fill: currentColor;
}

.fancybox-button--share svg path {
  stroke-width: 1;
}

.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px;
}

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s,visibility 0s,color .25s;
}

.fancybox-navigation .fancybox-button:after {
  content: "";
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px;
}

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10;
}

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s;
}

.fancybox-close-small:focus {
  outline: none;
}

.fancybox-close-small:hover svg {
  opacity: 1;
}

.fancybox-slide--iframe .fancybox-close-small, .fancybox-slide--image .fancybox-close-small, .fancybox-slide--video .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px;
}

.fancybox-slide--iframe .fancybox-close-small:hover svg, .fancybox-slide--image .fancybox-close-small:hover svg, .fancybox-slide--video .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none;
}

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px;
  right: 0;
}

.fancybox-caption:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1;
}

.fancybox-caption:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: "";
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0;
}

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

.fancybox-loading {
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(99, 99, 99, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x > ul {
  overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px);
  }
}

body {
  min-width: 320px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #2a2242;
}

@media only screen and (max-width: 666px) {
  body {
    line-height: 1.2;
  }
}

body.no-scroll {
  overflow: hidden;
}

button {
  background: none;
}

a {
  text-decoration: none;
}

.out {
  overflow: hidden;
}

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
}

@media only screen and (max-width: 1023px) {
  .center {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .center {
    padding: 0 17px;
  }
}

.btn {
  height: 35px;
  padding: 8px 18px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
  transition: all .3s;
}

.btn_pink {
  background: #ff3366;
  color: #fff;
}

.btn_pink:hover {
  background: #ff5c85;
}

.btn_border-white {
  position: relative;
  border: solid 2px #fff;
  color: #fff;
}

.btn_border-white:before, .btn_border-white:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  transition: all .3s;
}

.btn_border-white:before {
  background-image: linear-gradient(295deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}

.btn_border-white:after {
  background-image: linear-gradient(295deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  visibility: hidden;
  opacity: 0;
}

.btn_border-white:hover:after {
  visibility: visible;
  opacity: 1;
}

.btn_border-pink {
  border: 2px solid #ff3366;
  color: #ff3366;
}

.btn_border-pink:hover {
  background: #ff3366;
  color: #fff;
}

.btn_border-grey {
  border: 1px solid #ABB0BA;
  color: #ABB0BA;
}

.btn_border-grey:hover {
  background: #ABB0BA;
  color: #fff;
}

a.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 767px) {
  .header:before {
    display: none;
  }
}

.header__center {
  position: relative;
  z-index: 2;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header__center {
    padding: 0;
  }
}

.header__logo {
  flex: 0 0 185px;
  width: 185px;
  font-size: 0;
}

@media only screen and (max-width: 1229px) {
  .header__logo {
    flex: 0 0 140px;
    width: 140px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__logo {
    flex: 0 0 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo {
    flex: 0 0 120px;
    width: 120px;
  }
}

.header__container {
  flex: 0 0 calc(100% - 185px);
  width: calc(100% - 185px);
}

@media only screen and (max-width: 1229px) {
  .header__container {
    flex: 0 0 calc(100% - 140px);
    width: calc(100% - 140px);
  }
}

@media only screen and (max-width: 1023px) {
  .header__container {
    flex: 0 0 calc(100% - 100px);
    width: calc(100% - 100px);
  }
}

@media only screen and (max-width: 767px) {
  .header__container {
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
  }
}

.header__logo .header__pic {
  width: 100%;
}

.header__top {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .header__top {
    justify-content: flex-end;
    height: 70px;
    margin-bottom: 40px;
    padding: 0 17px;
  }
}

.header__nav {
  display: flex;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .header__nav {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 10;
    width: 200px;
    padding: 15px 20px;
    border-width: 0 1px 2px;
    border-style: solid;
    border-color: #ff3366;
    background: #fff;
  }
}

.header__nav .header__link {
  padding: 10px 16px 8px;
  font-size: 14px;
  color: #fff;
  transition: all .3s;
}

@media only screen and (max-width: 1229px) {
  .header__nav .header__link {
    padding: 9px 12px 8px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__nav .header__link {
    padding: 9px 5px 8px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav .header__link {
    display: block;
    padding: 0;
    font-size: 14px;
    color: #2a2242;
  }
}

.header__nav .header__link:hover {
  color: #ffb3c6;
}

@media only screen and (max-width: 767px) {
  .header__nav .header__link:hover {
    color: #2a2242;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav .header__link:not(:last-child) {
    margin-bottom: 20px;
  }
}

.header__social {
  margin-right: 27px;
  padding-top: 2px;
}

@media only screen and (max-width: 1023px) {
  .header__social {
    display: none;
  }
}

.header__language {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header__language {
    display: none;
  }
}

.header__language .header__link {
  position: relative;
  padding: 10px 15px 8px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  transition: all .3s;
}

.header__language .header__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 3px;
  margin: 0 auto;
  background: #ff3366;
  transition: all .3s;
}

.header__language .header__link.active {
  background: #f1f2f4;
  color: #586377;
}

.header__language .header__link.active:before {
  width: 100%;
}

.header__body {
  display: flex;
  align-items: center;
  padding: 20px 34px 14px 26px;
}

@media only screen and (max-width: 1229px) {
  .header__body {
    padding: 25px 34px 19px 26px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__body {
    padding: 8px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header__body {
    margin-left: -120px;
    padding: 15px 20px;
  }
}

.header__info {
  margin-right: auto;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 1023px) {
  .header__info {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .header__info {
    font-size: 12px;
    text-transform: none;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .header__info br {
    display: none;
  }
}

.header__phones {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .header__phones {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    flex-direction: column;
    width: 200px;
    padding: 15px 20px;
    border-width: 0 1px 2px;
    border-style: solid;
    border-color: #ff3366;
    background: #fff;
  }
}

.header__phone {
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
  transition: all .3s;
}

@media only screen and (max-width: 1023px) {
  .header__phone {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .header__phone {
    font-size: 14px;
    color: #2a2242;
  }
}

.header__phone:hover {
  color: #ffb3c6;
}

@media only screen and (max-width: 767px) {
  .header__phone:hover {
    color: #2a2242;
  }
}

.header__phone:not(:last-child) {
  margin-bottom: 2px;
}

@media only screen and (max-width: 767px) {
  .header__phone:not(:last-child) {
    margin-bottom: 10px;
  }
}

.header__bot {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 1229px) {
  .header__bot {
    margin-left: -140px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__bot {
    margin-left: -100px;
  }
}

.header__menu {
  flex: 0 0 calc(100% - 310px);
  width: calc(100% - 310px);
}

@media only screen and (max-width: 1023px) {
  .header__menu {
    flex: 0 0 calc(100% - 200px);
    width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 767px) {
  .header__menu {
    display: none;
    position: absolute;
    top: 110px;
    right: 0;
    left: 120px;
    flex: 0 0 auto;
    width: auto;
  }
}

.header__call {
  flex: 0 0 310px;
  width: 310px;
  padding-left: 15px;
}

@media only screen and (max-width: 1023px) {
  .header__call {
    flex: 0 0 200px;
    width: 200px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header__call {
    display: none;
  }
}

.header__btn {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header__btn {
    display: inline-block;
  }
}

.header__btn .icon {
  font-size: 25px;
  fill: #fff;
}

.header__services {
  display: none;
  position: absolute;
  top: 70px;
  right: 0;
  left: 120px;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #fff;
  border-bottom: 2px solid #ff3366;
}

@media only screen and (max-width: 767px) {
  .header__services {
    display: flex;
  }
}

.header__icon {
  margin-right: 10px;
  font-size: 0;
}

.header__img {
  width: 15px;
}

.header__services .header__text {
  position: relative;
  margin: 0;
  padding: 2px 20px 0 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #2a2242;
}

.header__services .header__text:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 10px;
  height: 6px;
  margin-top: -2px;
  background: url("../img/arrow-down-black.svg") no-repeat 0 0/10px 6px;
  transition: all .3s;
}

.header__services.active .header__text:before {
  transform: rotate(180deg);
}

.burger {
  display: none;
  position: relative;
  z-index: 20;
  width: 30px;
  height: 17px;
  margin-left: 30px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .burger {
    display: inline-block;
  }
}

.burger__lines {
  position: relative;
  display: block;
  width: 24px;
  height: 2px;
  margin: 7px 0 0;
  background: #fff;
}

.burger__lines:before, .burger__lines:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  height: 2px;
  background: #fff;
  transition: all .2s;
}

.burger__lines:before {
  top: -7px;
  width: 30px;
}

.burger__lines:after {
  bottom: -7px;
  width: 17px;
}

.burger.active .burger__lines {
  top: 0;
  transform: rotate(-44deg);
  width: 24px;
}

.burger.active .burger__lines:before {
  top: 0;
  transform: rotate(86deg);
  width: 24px;
}

.burger.active .burger__lines:after {
  top: 0;
  width: 34px;
  opacity: 0;
  transform: rotate(180deg);
}

.call {
  position: relative;
}

.call__btn {
  width: 100%;
  height: 50px;
}

@media only screen and (max-width: 1023px) {
  .call__btn {
    padding: 0;
    font-size: 14px;
  }
}

.call__wrap {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-top: 30px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transition: all .3s;
}

@media only screen and (max-width: 1023px) {
  .call__wrap {
    left: auto;
    width: 220px;
  }
}

.call__wrap.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.call__arrow {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .call__arrow {
    left: auto;
    right: 89px;
    transform: translateX(0);
  }
}

.call__arrow .icon {
  font-size: 7px;
  fill: #fff;
}

.call__form {
  padding: 30px 20px 30px;
  background: #F1F2F4;
}

@media only screen and (max-width: 1023px) {
  .call__form {
    padding: 25px 10px;
  }
}

.call__field:not(:last-child) {
  margin-bottom: 20px;
}

.call__fieldset {
  margin-bottom: 30px;
}

.call__form .call__btn {
  width: auto;
  height: 40px;
  padding: 0 14px;
}

.field__label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px;
}

@media only screen and (max-width: 666px) {
  .field__label {
    margin-bottom: 8px;
  }
}

.field__input, .field__textarea {
  width: 100%;
  border: 1px solid #ABB0BA;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #2a2242;
}

.field__input::placeholder, .field__textarea::placeholder {
  color: #2a2242;
}

.field__input:focus, .field__textarea:focus {
  border-color: #ff3366;
}

.field__input {
  height: 40px;
  padding: 0 15px;
}

.field__textarea {
  height: 136px;
  padding: 10px 12px;
  resize: none;
}

@media only screen and (max-width: 666px) {
  .field__textarea {
    height: 100px;
  }
}

.content h2 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 400;
}

@media only screen and (max-width: 1023px) {
  .content h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .content h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 666px) {
  .content h2 {
    margin-bottom: 25px;
    font-size: 20px;
  }
}

.content h3 {
  margin-bottom: 20px;
  padding-left: 18px;
  border-left: 3px solid #ff3366;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 666px) {
  .content h3 {
    padding-left: 15px;
    font-size: 20px;
  }
}

.content p {
  font-weight: 300;
}

@media only screen and (max-width: 666px) {
  .content p {
    font-size: 14px;
  }
}

.content p:not(:last-child) {
  margin-bottom: 15px;
}

.content blockquote {
  padding-left: 20px;
  border-left: 3px solid #ff3366;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 400;
}

@media only screen and (max-width: 1023px) {
  .content blockquote {
    font-size: 20px;
  }
}

@media only screen and (max-width: 666px) {
  .content blockquote {
    padding-left: 15px;
    border-width: 2px;
    font-size: 16px;
  }
}

.content blockquote:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .content blockquote:not(:last-child) {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 474px) {
  .content blockquote:not(:last-child) {
    margin-bottom: 20px;
  }
}

.content small {
  display: block;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  color: #8190b0;
}

.content small:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 666px) {
  .content small:not(:last-child) {
    margin-bottom: 20px;
  }
}

.content big {
  font-size: 20px;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .content big {
    font-size: 16px;
  }
}

.content figure[align="center"] {
  font-size: 0;
}

.content figure[align="center"] img {
  max-width: 100%;
}

.content figure[align="center"]:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 666px) {
  .content figure[align="center"]:not(:last-child) {
    margin-bottom: 20px;
  }
}

.content figure[align="right"] {
  float: right;
  margin: 0 0 20px 20px;
}

@media only screen and (max-width: 1023px) {
  .content figure[align="right"] {
    width: 45%;
  }
}

@media only screen and (max-width: 666px) {
  .content figure[align="right"] {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .content figure[align="right"] img {
    width: 100%;
  }
}

.content figure[align="left"] {
  float: left;
  margin: 0 20px 20px 0;
}

@media only screen and (max-width: 1023px) {
  .content figure[align="left"] {
    width: 45%;
  }
}

@media only screen and (max-width: 666px) {
  .content figure[align="left"] {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .content figure[align="left"] img {
    width: 100%;
  }
}

.content ul {
  padding-left: 25px;
  list-style-type: square;
}

@media only screen and (max-width: 666px) {
  .content ul {
    padding-left: 20px;
  }
}

.content ul li {
  font-size: 16px;
  line-height: 1.3;
}

@media only screen and (max-width: 666px) {
  .content ul li {
    font-size: 14px;
  }
}

.content ul li:not(:last-child) {
  margin-bottom: 15px;
}

@media only screen and (max-width: 666px) {
  .content ul li:not(:last-child) {
    margin-bottom: 8px;
  }
}

.content ul:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 666px) {
  .content ul:not(:last-child) {
    margin-bottom: 25px;
  }
}

.content ul + hr {
  margin-top: -20px;
}

@media only screen and (max-width: 666px) {
  .content ul + hr {
    margin-top: 0;
  }
}

.content strong {
  display: block;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .content strong {
    font-size: 24px;
  }
}

@media only screen and (max-width: 666px) {
  .content strong {
    margin-bottom: 12px;
    font-size: 18px;
  }
}

.content hr {
  margin: 20px -15px 40px;
}

@media only screen and (max-width: 767px) {
  .content hr {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 666px) {
  .content hr {
    margin: 20px 0 30px;
  }
}

.content b {
  font-weight: 700;
}

.content table {
  max-width: 620px;
  margin: 0 0 20px 10px;
  border-collapse: separate;
  border-spacing: 10px 0;
}

@media only screen and (max-width: 767px) {
  .content table {
    margin-left: 0;
  }
}

@media only screen and (max-width: 666px) {
  .content table {
    margin: 0 0 20px 0;
    border-spacing: 5px 0;
  }
}

.content td {
  padding: 10px 0 7px;
  font-size: 14px;
  color: #2a2242;
}

@media only screen and (max-width: 666px) {
  .content td {
    font-size: 13px;
  }
}

.content td:first-child {
  border-bottom: 1px solid #2a2242;
  font-weight: 600;
}

.content td:nth-child(2) {
  border-bottom: 1px solid #7F7A8E;
  font-weight: 300;
}

.social {
  display: flex;
  align-items: center;
}

.social__link {
  font-size: 0;
}

.social__link .icon-facebook {
  font-size: 16px;
}

.social__link .icon-instagram {
  font-size: 18px;
}

.social__link .icon-youtube {
  font-size: 14px;
}

.social__link:not(:last-child) {
  margin-right: 10px;
}

.social__item {
  font-size: 0;
}

.social__item:not(:last-child) {
  margin-right: 18px;
}

.social__item:hover .social__pic {
  transform: scale(1.1);
}

.social__pic {
  width: 32px;
  transition: all .3s;
}

@media only screen and (max-width: 666px) {
  .social__pic {
    width: 22px;
  }
}

.social_white .social__link .icon {
  fill: #f1f2f4;
  transition: all .3s;
}

.social_white .social__link:hover .icon {
  fill: #ffb3c6;
}

.social_black .social__link .icon {
  fill: #2a2242;
  transition: all .3s;
}

.social_black .social__link:hover .icon {
  fill: #604d96;
}

.menu {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .menu {
    flex-direction: column;
    padding: 15px 20px;
    border-bottom: 2px solid #ff3366;
    background: #fff;
  }
}

.menu__in {
  display: flex;
  flex: 0 0 calc(100% - 120px);
  width: calc(100% - 120px);
}

@media only screen and (max-width: 1023px) {
  .menu__in {
    flex: 0 0 calc(100% - 90px);
    width: calc(100% - 90px);
  }
}

@media only screen and (max-width: 767px) {
  .menu__in {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    width: 100%;
  }
}

.menu__action {
  position: relative;
  flex: 0 0 120px;
  width: 120px;
  padding: 16px 20px 15px 5px;
  background: #f1f2f4;
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
  color: #ff3366;
  transition: all .3s;
}

@media only screen and (max-width: 1023px) {
  .menu__action {
    flex: 0 0 90px;
    width: 90px;
    padding: 16px 5px 15px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .menu__action {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    text-align: left;
    background: none;
    font-size: 14px;
  }
}

.menu__action:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #ff3366;
}

@media only screen and (max-width: 767px) {
  .menu__action:before {
    display: none;
  }
}

.menu__action:hover {
  color: #ff809f;
}

.menu__action:hover:before {
  background: #ff809f;
}

.menu__filter {
  position: relative;
  flex: 1 0 auto;
}

@media only screen and (max-width: 767px) {
  .menu__filter {
    flex: 0 0 auto;
    margin-bottom: 20px;
  }
}

.menu__filter:before {
  display: none;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin: 12px 0 0 -6px;
  width: 12px;
  height: 7px;
  background: url("../img/arrow-down.svg") no-repeat 0 0/12px 7px;
}

.menu__filter.active .menu__box {
  display: block;
}

@media only screen and (max-width: 767px) {
  .menu__filter.active .menu__box {
    display: none;
  }
}

.menu__filter.active .menu__text {
  background: #fff;
}

@media only screen and (max-width: 767px) {
  .menu__filter.active .menu__text {
    background: none;
  }
}

.menu__filter.active .menu__text:before {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .menu__filter.active .menu__text:before {
    width: 0;
  }
}

.menu__filter.active:before {
  display: block;
}

@media only screen and (max-width: 767px) {
  .menu__filter.active:before {
    display: none;
  }
}

.menu__filter:first-child .menu__stage {
  padding-left: 9px;
}

@media only screen and (max-width: 767px) {
  .menu__filter:first-child .menu__stage {
    padding-left: 0;
  }
}

.menu__stage {
  position: relative;
  display: block;
  padding: 0 13px;
  background: #f1f2f4;
}

@media only screen and (max-width: 1023px) {
  .menu__stage {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .menu__stage {
    background: none;
  }
}

.menu__stage:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -5px;
  height: 12px;
  width: 1px;
  background: #ABB0BA;
}

@media only screen and (max-width: 767px) {
  .menu__stage:before {
    display: none;
  }
}

.menu__text {
  position: relative;
  padding: 16px 5px 15px;
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
  color: #2a2242;
  transition: all .3s;
}

@media only screen and (max-width: 1023px) {
  .menu__text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .menu__text {
    padding: 0;
    text-align: left;
    font-size: 14px;
  }
}

.menu__text:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  margin: 0 auto;
  background: #ff3366;
  transition: all .3s;
}

.menu__box {
  display: none;
  position: absolute;
  top: 100%;
  padding-top: 30px;
  border-bottom: 2px solid #2a2242;
}

@media only screen and (max-width: 767px) {
  .menu__box {
    position: relative;
    top: auto;
    padding-top: 15px;
    border: none;
  }
}

.menu__item {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .menu__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.menu__category {
  position: relative;
  display: block;
  width: 225px;
  padding: 15px 20px;
  background: #f1f2f4;
  line-height: 1.2;
  color: #2a2242;
}

@media only screen and (max-width: 767px) {
  .menu__category {
    width: 100%;
    padding: 0;
    background: none;
    font-size: 14px;
  }
}

.menu__category:before {
  display: none;
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 6px;
  height: 12px;
  margin-top: -6px;
  background: url("../img/arrow-right.svg") no-repeat 0 0/6px 12px;
}

@media only screen and (max-width: 767px) {
  .menu__category:before {
    display: none;
  }
}

.menu__item:not(:last-child) .menu__category:after {
  content: "";
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  height: 1px;
  background: #ABB0BA;
}

@media only screen and (max-width: 767px) {
  .menu__item:not(:last-child) .menu__category:after {
    display: none;
  }
}

.menu__list {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: 6px;
}

.menu__list:before {
  content: "";
  position: absolute;
  left: 6px;
  right: 0;
  bottom: -2px;
  height: 2px;
  background: #2a2242;
}

.menu__link {
  position: relative;
  display: block;
  width: 228px;
  padding: 15px 20px;
  background: #f1f2f4;
  line-height: 1.2;
  color: #2a2242;
}

.menu__link:before {
  display: none;
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 6px;
  height: 12px;
  margin-top: -6px;
  background: url("../img/arrow-right.svg") no-repeat 0 0/6px 12px;
}

.menu__link:not(:last-child):after {
  content: "";
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: -1px;
  height: 2px;
  background: #ABB0BA;
}

body:not(.touch-device) .menu__filter:hover .menu__box {
  display: block;
}

body:not(.touch-device) .menu__filter:hover .menu__text {
  background: #fff;
}

body:not(.touch-device) .menu__filter:hover .menu__text:before {
  width: 100%;
}

body:not(.touch-device) .menu__filter:hover:before {
  display: block;
}

body:not(.touch-device) .menu__item:hover .menu__category {
  background: #fff;
  box-shadow: inset 3px 0 0 #ff3366;
}

body:not(.touch-device) .menu__item:hover .menu__category:before {
  display: block;
}

body:not(.touch-device) .menu__item:hover .menu__list {
  display: block;
}

body:not(.touch-device) .menu__link:hover {
  background: #fff;
  box-shadow: inset 3px 0 0 #ff3366;
}

body:not(.touch-device) .advantages__preview:hover .advantages__pic {
  transform: scale(1.2);
}

.home {
  position: relative;
  padding: 240px 0 190px;
  background: linear-gradient(152deg, rgba(255, 51, 102, 0), rgba(255, 51, 102, 0.25)), linear-gradient(0deg, rgba(42, 35, 66, 0.7), rgba(42, 35, 66, 0.7)), url("../img/bg-home.jpg") no-repeat 50% 0/cover;
}

@media only screen and (max-width: 1229px) {
  .home {
    padding: 220px 0 190px;
  }
}

@media only screen and (max-width: 1023px) {
  .home {
    padding: 190px 0 210px;
  }
}

@media only screen and (max-width: 767px) {
  .home {
    padding: 170px 0 190px;
    background-image: linear-gradient(147deg, #9E2B56, #2a2242);
  }
}

@media only screen and (max-width: 666px) {
  .home {
    padding: 180px 0 190px;
  }
}

.home__slider {
  margin-bottom: 70px;
}

@media only screen and (max-width: 1023px) {
  .home__slider {
    margin-bottom: 50px;
  }
}

.home__wrap {
  width: 62%;
  margin-left: auto;
}

@media only screen and (max-width: 1229px) {
  .home__wrap {
    width: 75%;
  }
}

@media only screen and (max-width: 1023px) {
  .home__wrap {
    width: 90%;
  }
}

@media only screen and (max-width: 474px) {
  .home__wrap {
    width: 100%;
  }
}

.home__title {
  position: relative;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 666px) {
  .home__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 474px) {
  .home__title {
    margin-bottom: 13px;
    padding-left: 25px;
    font-size: 24px;
  }
}

.home__title:before {
  content: "";
  position: absolute;
  top: 0;
  left: -43px;
  width: 23px;
  height: 47px;
  background: url("../img/symbol.svg") no-repeat 0 0/23px 47px;
}

@media only screen and (max-width: 666px) {
  .home__title:before {
    width: 17px;
    height: 35px;
    background-size: 17px 35px;
  }
}

@media only screen and (max-width: 474px) {
  .home__title:before {
    left: 0;
  }
}

.home__info {
  font-size: 20px;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .home__info {
    font-size: 16px;
  }
}

@media only screen and (max-width: 474px) {
  .home__info {
    padding-left: 25px;
  }
}

.slider {
  padding-bottom: 30px;
}

@media only screen and (max-width: 474px) {
  .slider {
    margin: 0 -17px 25px;
  }
}

.slider__list {
  padding: 0 52px;
}

@media only screen and (max-width: 666px) {
  .slider__list {
    padding: 0;
  }
}

.slider__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 45px;
}

@media only screen and (max-width: 1023px) {
  .slider__item {
    padding: 0;
  }
}

.slider__item:before {
  content: "";
  position: absolute;
  top: 18px;
  right: 0;
  left: 0;
  z-index: -1;
  bottom: 16px;
  border-bottom: 2px solid #ff3366;
  background: rgba(241, 242, 244, 0.2);
}

@media only screen and (max-width: 1229px) {
  .slider__item:before {
    top: 0;
    bottom: 0;
  }
}

.slider__preview {
  flex: 0 0 473px;
  width: 473px;
  font-size: 0;
}

@media only screen and (max-width: 1229px) {
  .slider__preview {
    flex: 0 0 350px;
    width: 350px;
  }
}

@media only screen and (max-width: 1023px) {
  .slider__preview {
    flex: 0 0 100%;
    width: 100%;
  }
}

.slider__wrap {
  flex: 0 0 calc(100% - 473px);
  width: calc(100% - 473px);
  padding: 40px 70px 40px 60px;
  color: #fff;
}

@media only screen and (max-width: 1229px) {
  .slider__wrap {
    flex: 0 0 calc(100% - 350px);
    width: calc(100% - 350px);
    padding: 40px 70px 40px 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .slider__wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    flex: 0 0 100%;
    width: 100%;
    padding: 30px;
    background: rgba(42, 34, 66, 0.5);
  }
}

@media only screen and (max-width: 767px) {
  .slider__wrap {
    padding: 25px;
  }
}

@media only screen and (max-width: 666px) {
  .slider__wrap {
    padding: 15px 20px;
  }
}

.slider__pic {
  width: 100%;
}

.slider__title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1.5;
}

@media only screen and (max-width: 1023px) {
  .slider__title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 666px) {
  .slider__title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 474px) {
  .slider__title {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.2;
  }
}

.slider__info {
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 1.22;
}

@media only screen and (max-width: 1229px) {
  .slider__info {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .slider__info {
    display: none;
  }
}

.slider__btn {
  width: 228px;
  height: 50px;
}

@media only screen and (max-width: 666px) {
  .slider__btn {
    width: 131px;
    height: 34px;
    padding: 0;
    border-width: 1px;
    font-size: 14px;
  }
}

.slider .owl-prev,
.slider .owl-next {
  position: absolute;
  top: 45px;
  bottom: 45px;
  width: 40px;
  background: rgba(255, 255, 255, 0.1);
  transition: all .3s;
  font-size: 0;
}

@media only screen and (max-width: 666px) {
  .slider .owl-prev,
  .slider .owl-next {
    top: auto;
    bottom: 15px;
    width: 65px;
    height: 34px;
    background: #2a2242;
  }
}

.slider .owl-prev .icon,
.slider .owl-next .icon {
  font-size: 28px;
  fill: #fff;
}

@media only screen and (max-width: 666px) {
  .slider .owl-prev .icon,
  .slider .owl-next .icon {
    font-size: 16px;
  }
}

.slider .owl-prev:hover,
.slider .owl-next:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media only screen and (max-width: 666px) {
  .slider .owl-prev:hover,
  .slider .owl-next:hover {
    background: #2a2242;
  }
}

.slider .owl-prev {
  left: 0;
}

@media only screen and (max-width: 666px) {
  .slider .owl-prev {
    left: auto;
    right: 83px;
  }
}

.slider .owl-next {
  right: 0;
}

@media only screen and (max-width: 666px) {
  .slider .owl-next {
    right: 15px;
  }
}

.slider .owl-dots {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  display: flex;
}

@media only screen and (max-width: 474px) {
  .slider .owl-dots {
    padding: 0 17px;
  }
}

.slider .owl-dots:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 474px) {
  .slider .owl-dots:before {
    top: 1px;
  }
}

.slider .owl-dot {
  flex: 1 0 auto;
  height: 6px;
  transition: all .3s;
}

@media only screen and (max-width: 474px) {
  .slider .owl-dot {
    height: 4px;
  }
}

.slider .owl-dot.active {
  background: #ff3366;
}

.title {
  padding-left: 20px;
  border-left: 3px solid #ff3366;
  font-size: 24px;
  font-weight: 400;
}

.title_fs18 {
  padding-left: 12px;
  font-size: 18px;
}

.title_fs20 {
  padding-left: 18px;
  font-size: 20px;
}

.title_white {
  color: #fff;
}

.desc {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  color: #8190b0;
}

.production {
  position: relative;
  z-index: 1;
  margin-top: -120px;
  padding-bottom: 56px;
}

@media only screen and (max-width: 1229px) {
  .production {
    margin-top: -140px;
  }
}

.production:before, .production:after {
  content: "";
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media only screen and (max-width: 1229px) {
  .production:before, .production:after {
    top: 140px;
  }
}

@media only screen and (max-width: 666px) {
  .production:before, .production:after {
    top: 150px;
  }
}

.production:before {
  background: url("../img/bg-production.jpg") no-repeat 50% 50%/cover;
}

.production:after {
  background: rgba(255, 255, 255, 0.7);
}

.production__row {
  position: relative;
  display: flex;
  padding: 40px 5px 0;
}

@media only screen and (max-width: 1229px) {
  .production__row {
    margin: 0 -15px;
    padding: 40px 0 0;
    border: none;
  }
}

@media only screen and (max-width: 1023px) {
  .production__row {
    flex-direction: column;
    margin: 0;
  }
}

@media only screen and (max-width: 474px) {
  .production__row {
    padding-top: 20px;
  }
}

.production__row:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #ABB0BA;
}

@media only screen and (max-width: 1229px) {
  .production__row:before {
    left: 15px;
    right: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .production__row:before {
    left: 0;
    right: 0;
  }
}

.production__aside {
  flex: 0 0 calc(33% - 30px);
  width: calc(33% - 30px);
  margin: 0 15px;
}

@media only screen and (max-width: 1023px) {
  .production__aside {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

.production__container {
  flex: 0 0 calc(67% - 30px);
  width: calc(67% - 30px);
  margin: 0 15px;
}

@media only screen and (max-width: 1023px) {
  .production__container {
    flex: 0 0 100%;
    width: 100%;
    margin: 0 0 20px;
    order: -1;
  }
}

@media only screen and (max-width: 474px) {
  .production__container {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 1023px) {
  .production__aside .production__info {
    display: none;
  }
}

.production__container .production__info {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .production__container .production__info {
    display: block;
  }
}

.production__info {
  position: relative;
  margin-bottom: 90px;
  padding-left: 52px;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1229px) {
  .production__info {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .production__info {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 666px) {
  .production__info {
    padding-left: 40px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 474px) {
  .production__info {
    margin-bottom: 35px;
    font-size: 20px;
  }
}

.production__info:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  height: 47px;
  background: url("../img/symbol.svg") no-repeat 0 0/23px 47px;
}

@media only screen and (max-width: 666px) {
  .production__info:before {
    width: 17px;
    height: 35px;
    background-size: 17px 35px;
  }
}

.production__content {
  margin-bottom: 40px;
}

.production__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

@media only screen and (max-width: 666px) {
  .production__list {
    margin: 0 -8px;
  }
}

@media only screen and (max-width: 474px) {
  .production__list {
    margin: 0;
  }
}

.production__item {
  position: relative;
  flex: 0 0 calc(50% - 30px);
  width: calc(50% - 30px);
  margin: 0 15px 17px;
  font-size: 0;
  overflow: hidden;
}

@media only screen and (max-width: 666px) {
  .production__item {
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px 15px;
  }
}

@media only screen and (max-width: 474px) {
  .production__item {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

.production__item:before, .production__item:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.production__item:before {
  z-index: 1;
  height: 3px;
  background-image: linear-gradient(to right, #ff3366, #e3097e);
}

.production__item:after {
  top: 0;
  background-image: linear-gradient(198deg, rgba(42, 34, 66, 0), rgba(42, 34, 66, 0.9));
}

@media only screen and (max-width: 474px) {
  .production__item:not(:last-child) {
    margin-bottom: 25px;
  }
}

.production__item:hover .production__pic {
  transform: scale(1.1);
}

.production__pic {
  width: 100%;
  transition: all .5s;
}

.production__name {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  z-index: 2;
  padding: 15px 10px 23px;
  border-top: 1px solid #fff;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 666px) {
  .production__name {
    left: 10px;
    right: 10px;
    padding: 10px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 474px) {
  .production__name {
    left: 25px;
    right: 25px;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .production__btns {
    text-align: center;
  }
}

.production__btn {
  width: 256px;
  height: 50px;
}

.head {
  position: relative;
  padding: 70px 0 23px;
  border-bottom: 1px solid #ABB0BA;
}

@media only screen and (max-width: 1023px) {
  .head {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 666px) {
  .head {
    padding: 25px 0 15px;
  }
}

.head:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 23px;
  height: 47px;
  margin-left: -12px;
  background: url("../img/symbol.svg") no-repeat 0 0/23px 47px;
}

@media only screen and (max-width: 666px) {
  .head:before {
    left: 0;
    width: 17px;
    height: 37px;
    margin: 0;
    background-size: 17px 37px;
  }
}

.head__row {
  display: flex;
  margin: 0 -42px;
}

@media only screen and (max-width: 1023px) {
  .head__row {
    margin: 0 -20px;
  }
}

@media only screen and (max-width: 666px) {
  .head__row {
    display: block;
    margin: 0;
  }
}

.head__col {
  flex: 0 0 calc(50% - 84px);
  width: calc(50% - 84px);
  margin: 0 42px;
}

@media only screen and (max-width: 1023px) {
  .head__col {
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
    margin: 0 20px;
  }
}

@media only screen and (max-width: 666px) {
  .head__col {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 666px) {
  .head__col:nth-child(2) {
    margin-top: 15px;
  }
}

.head__title {
  text-align: right;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
}

@media only screen and (max-width: 1229px) {
  .head__title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .head__title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 666px) {
  .head__title {
    padding-left: 30px;
    text-align: left;
    font-size: 20px;
  }
}

.head__info {
  font-size: 16px;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .head__info {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1023px) {
  .head__info br {
    display: none;
  }
}

.head__font {
  white-space: nowrap;
}

.head_white .head__title {
  color: #fff;
}

.advantages {
  position: relative;
  overflow: hidden;
}

.advantages__bg {
  position: absolute;
  top: -10%;
  right: 0;
  bottom: -40%;
  left: 0;
  background: url("../img/bg-advantages.jpg") no-repeat 50% 50%/cover;
}

@media only screen and (max-width: 767px) {
  .advantages__bg {
    background: none;
  }
}

.advantages__bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(-28deg, rgba(255, 51, 102, 0), rgba(255, 51, 102, 0.5)), linear-gradient(0deg, rgba(42, 35, 66, 0.7), rgba(42, 35, 66, 0.7));
  opacity: .7;
}

@media only screen and (max-width: 767px) {
  .advantages__bg:before {
    background-image: linear-gradient(142deg, #9E2B56, #2a2242);
    opacity: 1;
  }
}

.advantages__center {
  position: relative;
  z-index: 2;
}

.advantages__wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 45px 0 7px;
}

@media only screen and (max-width: 474px) {
  .advantages__wrap {
    padding: 35px 0 15px;
  }
}

.advantages__item {
  flex: 0 0 33.33333%;
  width: 33.33333%;
  margin-bottom: 60px;
  padding: 0 30px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .advantages__item {
    margin-bottom: 45px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .advantages__item {
    flex: 0 0 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 474px) {
  .advantages__item {
    margin-bottom: 35px;
  }
}

.advantages__preview {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 81px;
  margin-bottom: 20px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .advantages__preview {
    margin-bottom: 15px;
  }
}

.advantages__pic {
  transition: all .5s;
}

.advantages__pic_1 {
  width: 83px;
}

.advantages__pic_2 {
  width: 70px;
}

.advantages__pic_3 {
  width: 97px;
}

.advantages__pic_4 {
  width: 70px;
}

.advantages__pic_5 {
  width: 55px;
}

.advantages__pic_6 {
  width: 92px;
}

.advantages__category {
  margin-bottom: 10px;
  font-size: 18px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .advantages__category {
    margin: 0;
  }
}

@media only screen and (max-width: 474px) {
  .advantages__category {
    font-size: 14px;
  }
}

.advantages__text {
  max-width: 270px;
  margin: 0 auto;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 767px) {
  .advantages__text {
    display: none;
  }
}

.advantages__text_big {
  max-width: 310px;
}

.partners {
  background: #f1f2f4;
}

.partners__body {
  padding: 50px 0 70px;
}

@media only screen and (max-width: 666px) {
  .partners__body {
    padding: 35px 0 45px;
  }
}

@media only screen and (max-width: 474px) {
  .partners__body {
    margin: 0 -17px;
  }
}

.partners__list {
  padding: 0 50px;
}

.partners__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135px;
  margin-bottom: 11px;
  padding: 20px 0;
  font-size: 0;
}

.partners__item {
  display: block;
  padding: 15px 15px 18px;
  text-align: center;
  color: #2a2242;
  transition: all .3s;
}

.partners__item:hover {
  background: #fff;
}

.partners__name {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 400;
}

.partners__site {
  font-size: 14px;
  line-height: 1.2;
  color: #8190b0;
}

.partners .owl-carousel .owl-item .partners__pic {
  max-width: 100%;
  max-height: 100%;
}

.partners .owl-carousel .owl-item .partners__pic_rehau {
  width: 201px;
  margin-top: -30px;
}

.partners .owl-carousel .owl-item .partners__pic_lisec {
  width: 193px;
}

.partners .owl-carousel .owl-item .partners__pic_shurtz {
  width: 185px;
}

.partners .owl-carousel .owl-item .partners__pic_gu {
  width: 125px;
}

.partners .owl-carousel .owl-item .partners__pic_gg {
  width: 145px;
}

.partners .owl-prev,
.partners .owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  background: #ABB0BA;
  transition: all .3s;
  font-size: 0;
}

.partners .owl-prev .icon,
.partners .owl-next .icon {
  font-size: 28px;
  fill: #fff;
}

.partners .owl-prev:hover,
.partners .owl-next:hover {
  background: #2a2242;
}

.partners .owl-prev {
  left: 0;
}

.partners .owl-next {
  right: 0;
}

.last {
  position: relative;
  overflow: hidden;
}

.last__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -30%;
  background: linear-gradient(-28deg, rgba(255, 51, 102, 0), rgba(255, 51, 102, 0.5)), linear-gradient(0deg, rgba(42, 35, 66, 0.7), rgba(42, 35, 66, 0.7)), url("../img/bg-last.jpg") no-repeat center/cover;
}

@media only screen and (max-width: 767px) {
  .last__wrapper {
    background-image: linear-gradient(142deg, #9E2B56, #2a2242);
  }
}

.last__center {
  position: relative;
  z-index: 2;
}

.last__head {
  padding-top: 50px;
}

.last__body {
  padding: 50px 20px 70px 115px;
}

@media only screen and (max-width: 1229px) {
  .last__body {
    padding: 50px 0 60px 0;
  }
}

@media only screen and (max-width: 666px) {
  .last__body {
    padding: 35px 0 40px;
  }
}

.last__wrap {
  display: flex;
  margin: 0 -50px;
}

@media only screen and (max-width: 1229px) {
  .last__wrap {
    margin: 0 -20px;
  }
}

@media only screen and (max-width: 666px) {
  .last__wrap {
    display: block;
    margin: 0;
  }
}

.last__col {
  margin: 0 50px;
}

@media only screen and (max-width: 1229px) {
  .last__col {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 666px) {
  .last__col {
    margin: 0;
  }
}

.last__col:first-child {
  flex: 0 0 calc(46% - 100px);
  width: calc(46% - 100px);
}

@media only screen and (max-width: 1229px) {
  .last__col:first-child {
    flex: 0 0 calc(46% - 40px);
    width: calc(46% - 40px);
  }
}

@media only screen and (max-width: 666px) {
  .last__col:first-child {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 474px) {
  .last__col:first-child {
    margin-bottom: 25px;
  }
}

.last__col:nth-child(2) {
  flex: 0 0 calc(54% - 100px);
  width: calc(54% - 100px);
}

@media only screen and (max-width: 1229px) {
  .last__col:nth-child(2) {
    flex: 0 0 calc(54% - 40px);
    width: calc(54% - 40px);
  }
}

@media only screen and (max-width: 666px) {
  .last__col:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
  }
}

.last__article {
  display: block;
}

.last__date {
  margin-bottom: 40px;
}

@media only screen and (max-width: 666px) {
  .last__date {
    margin-bottom: 20px;
  }
}

.last__preview {
  margin-bottom: 30px;
  font-size: 0;
}

@media only screen and (max-width: 474px) {
  .last__preview {
    margin: 0 -17px 20px;
  }
}

.last__pic {
  width: 100%;
}

.last__news {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}

.last__info {
  padding-right: 15px;
  font-size: 20px;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .last__info {
    font-size: 16px;
  }
}

.last__bg {
  padding: 30px 65px 38px 34px;
  background: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 1023px) {
  .last__bg {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 474px) {
  .last__bg {
    padding: 0;
    background: none;
  }
}

.last__list {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .last__list {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 474px) {
  .last__list {
    display: none;
  }
}

.last__item {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .last__item {
    display: block;
  }
}

.last__item:not(:last-child) {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .last__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.last__time {
  flex: 0 0 90px;
  width: 90px;
}

@media only screen and (max-width: 767px) {
  .last__time {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 10px;
  }
}

.last__box {
  flex: 0 0 calc(100% - 90px);
  width: calc(100% - 90px);
}

@media only screen and (max-width: 767px) {
  .last__box {
    flex: 0 0 100%;
    width: 100%;
  }
}

.last__day {
  font-size: 28px;
  line-height: 1.2;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .last__day {
    margin-right: 5px;
    font-size: 20px;
  }
}

.last__month {
  margin-bottom: 6px;
  line-height: 1.2;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .last__month {
    margin: 0 5px 0 0;
  }
}

.last__year {
  line-height: 1.2;
  color: #ABB0BA;
}

@media only screen and (max-width: 767px) {
  .last__year {
    padding-top: 2px;
  }
}

.last__title {
  margin-bottom: 10px;
}

.last__text {
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 767px) {
  .last__text {
    font-size: 15px;
  }
}

.last__btns {
  padding-left: 25px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .last__btns {
    padding-left: 0;
  }
}

.date {
  display: inline-block;
  border-bottom: 3px solid #ff3366;
  margin-bottom: 40px;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
}

.about {
  background: #f1f2f4;
}

@media only screen and (max-width: 1023px) {
  .about {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 666px) {
  .about {
    padding-bottom: 40px;
  }
}

.about__row {
  display: flex;
  padding: 40px 20px 70px;
}

@media only screen and (max-width: 1023px) {
  .about__row {
    position: relative;
    flex-direction: column;
    padding: 40px 0 80px;
  }
}

@media only screen and (max-width: 666px) {
  .about__row {
    padding: 30px 0 80px;
  }
}

.about__col:first-child {
  flex: 0 0 54%;
  width: 54%;
  padding-right: 60px;
}

@media only screen and (max-width: 1023px) {
  .about__col:first-child {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 666px) {
  .about__col:first-child {
    margin-bottom: 20px;
  }
}

.about__col:nth-child(2) {
  flex: 0 0 46%;
  width: 46%;
}

@media only screen and (max-width: 1023px) {
  .about__col:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 666px) {
  .about__content br {
    display: none;
  }
}

.about__content:not(:last-child) {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1023px) {
  .about__content:not(:last-child) {
    margin: 0;
  }
}

.about__content blockquote {
  padding-left: 18px;
  font-size: 20px;
}

.about__content blockquote:not(:last-child) {
  margin-bottom: 25px;
}

.about__gallery_sm {
  padding-right: 95px;
}

@media only screen and (max-width: 1229px) {
  .about__gallery_sm {
    padding-right: 0;
  }
}

.about__gallery_sm .about__preview:not(:last-child) {
  margin-bottom: 20px;
}

.about__preview {
  font-size: 0;
}

.about__preview:not(:last-child) {
  margin-bottom: 35px;
}

@media only screen and (max-width: 666px) {
  .about__preview:not(:last-child) {
    margin-bottom: 20px;
  }
}

.about__pic {
  width: 100%;
}

.about__btn {
  width: 289px;
  height: 49px;
}

@media only screen and (max-width: 1023px) {
  .about__btn {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.about_white {
  background: #fff;
}

@media only screen and (max-width: 1023px) {
  .about_white .about__row {
    padding-bottom: 0;
  }
}

.order {
  background: linear-gradient(to left top, rgba(255, 51, 102, 0) 0%, rgba(255, 51, 102, 0.4) 100%), #2A2242;
}

.order__form {
  display: flex;
  align-items: flex-end;
  margin: 0 -16px;
  padding: 85px 100px 95px;
}

@media only screen and (max-width: 1229px) {
  .order__form {
    padding: 70px 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .order__form {
    margin: 0;
    padding: 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .order__form {
    display: block;
    max-width: 400px;
    margin: 0 auto;
    padding: 45px 0 55px;
  }
}

@media only screen and (max-width: 666px) {
  .order__form {
    padding: 25px 0 45px;
  }
}

.order__col {
  margin: 0 16px;
}

@media only screen and (max-width: 1023px) {
  .order__col {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .order__col {
    margin: 0;
  }
}

.order__col:first-child {
  flex: 0 0 calc(38% - 32px);
  width: calc(38% - 32px);
}

@media only screen and (max-width: 1023px) {
  .order__col:first-child {
    flex: 0 0 calc(40% - 20px);
    width: calc(40% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .order__col:first-child {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 30px;
  }
}

.order__col:nth-child(2), .order__col:nth-child(3) {
  flex: 0 0 calc(30% - 32px);
  width: calc(30% - 32px);
}

@media only screen and (max-width: 767px) {
  .order__col:nth-child(2), .order__col:nth-child(3) {
    flex: 0 0 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .order__col:nth-child(2) {
    flex: 0 0 calc(38% - 20px);
    width: calc(38% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .order__col:nth-child(2) {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .order__col:nth-child(3) {
    flex: 0 0 calc(24% - 20px);
    width: calc(24% - 20px);
  }
}

.order__field .field__label {
  margin-bottom: 14px;
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
}

@media only screen and (max-width: 666px) {
  .order__field .field__label {
    margin-bottom: 8px;
  }
}

.order__field .field__input {
  height: 48px;
  border-width: 2px;
  background: none;
  font-size: 20px;
  color: #fff;
}

.order__field .field__input::placeholder {
  color: #fff;
}

.order__btn {
  height: 48px;
  width: 100%;
  border: 2px solid #ff3366;
  background: rgba(255, 51, 102, 0.7);
}

.order__btn:hover {
  background: #ff3366;
}

.footer {
  background: #f1f2f4;
}

.footer__body {
  padding-bottom: 45px;
}

@media only screen and (max-width: 1023px) {
  .footer__body {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 666px) {
  .footer__body {
    padding: 20px 0 0;
  }
}

@media only screen and (max-width: 474px) {
  .footer__body {
    padding: 20px 0 30px;
  }
}

.footer__top {
  display: flex;
  align-items: center;
  margin-bottom: 37px;
}

@media only screen and (max-width: 666px) {
  .footer__top {
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ABB0BA;
  }
}

.footer__logo {
  flex: 0 0 115px;
  width: 115px;
  padding-right: 25px;
  font-size: 0;
}

@media only screen and (max-width: 666px) {
  .footer__logo {
    flex: 0 0 90px;
    width: 90px;
    padding-right: 20px;
  }
}

.footer__wrap {
  display: flex;
  align-items: center;
  flex: 0 0 calc(100% - 115px);
  width: calc(100% - 115px);
  height: 82px;
  padding: 10px 0 5px;
  border-bottom: 1px solid #ABB0BA;
}

@media only screen and (max-width: 1023px) {
  .footer__wrap {
    display: block;
  }
}

@media only screen and (max-width: 666px) {
  .footer__wrap {
    display: flex;
    align-items: center;
    flex: 0 0 calc(100% - 90px);
    width: calc(100% - 90px);
    height: 64px;
    padding: 5px 0;
    border: none;
  }
}

.footer__logo .footer__pic {
  width: 100%;
}

.footer__info {
  margin-right: auto;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  color: #8190b0;
}

@media only screen and (max-width: 1023px) {
  .footer__info {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 666px) {
  .footer__info {
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__info br {
    display: none;
  }
}

.footer__nav {
  display: flex;
}

@media only screen and (max-width: 666px) {
  .footer__nav {
    display: none;
  }
}

.footer__box {
  position: relative;
  padding: 0 10px;
}

@media only screen and (max-width: 767px) {
  .footer__box {
    padding: 0;
  }
}

.footer__box:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -5px;
  height: 12px;
  width: 1px;
  background: #ABB0BA;
}

@media only screen and (max-width: 767px) {
  .footer__box:before {
    display: none;
  }
}

.footer__box:last-child {
  padding-right: 0;
}

.footer__box:last-child:before {
  display: none;
}

.footer__nav .footer__link {
  padding: 8px 20px 7px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  color: #2a2242;
  transition: all .3s;
}

@media only screen and (max-width: 1229px) {
  .footer__nav .footer__link {
    padding: 8px 10px 7px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__nav .footer__link {
    padding: 8px 10px 7px;
  }
}

.footer__nav .footer__link:hover {
  background: #8190B0;
  color: #fff;
}

.footer__row {
  display: flex;
  padding: 0 20px;
}

@media only screen and (max-width: 1023px) {
  .footer__row {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 474px) {
  .footer__row {
    padding: 0 40px;
  }
}

.footer__col {
  flex: 0 0 25%;
  width: 25%;
  padding-right: 20px;
}

@media only screen and (max-width: 1023px) {
  .footer__col {
    flex: 0 0 33.33%;
    width: 33.33%;
    margin-bottom: 30px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 666px) {
  .footer__col {
    flex: 0 0 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 474px) {
  .footer__col {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:last-child {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    align-items: center;
  }
}

@media only screen and (max-width: 666px) {
  .footer__col:last-child {
    display: block;
    flex: 0 0 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 474px) {
  .footer__col:last-child {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:last-child .footer__category {
    margin: 0 30px 0 0;
  }
}

@media only screen and (max-width: 666px) {
  .footer__col:last-child .footer__category {
    margin: 0 0 15px 0;
  }
}

.footer__category {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 666px) {
  .footer__category {
    margin-bottom: 8px;
  }
}

.footer__desc {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 474px) {
  .footer__desc {
    margin-bottom: 3px;
  }
}

.footer__text {
  font-size: 14px;
  font-weight: 300;
  color: #2a2242;
}

.footer__item:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (max-width: 666px) {
  .footer__item:not(:last-child) {
    margin-bottom: 8px;
  }
}

.footer__line {
  display: flex;
}

.footer__line .footer__desc {
  flex: 0 0 70px;
  width: 70px;
  padding-right: 10px;
}

.footer__control {
  padding: 5px 0;
  background: rgba(171, 176, 186, 0.3);
}

@media only screen and (max-width: 666px) {
  .footer__control {
    display: none;
  }
}

.footer__control .footer__center {
  display: flex;
  align-items: center;
  max-width: 1190px;
}

.footer__menu {
  display: flex;
  margin: 0 auto 0 -14px;
}

.footer__menu .footer__link {
  padding: 8px 14px;
  font-size: 14px;
  color: #ABB0BA;
  transition: all .3s;
}

.footer__menu .footer__link:hover {
  background: #8190B0;
  color: #fff;
}

.footer__counter {
  font-size: 0;
}

.footer__bottom {
  padding: 6px 0;
  background: #586377;
}

@media only screen and (max-width: 666px) {
  .footer__bottom {
    padding: 8px 0;
  }
}

.footer__bottom .footer__center {
  display: flex;
  align-items: center;
  max-width: 1190px;
}

@media only screen and (max-width: 666px) {
  .footer__bottom .footer__center {
    flex-direction: column;
  }
}

.footer__copyright {
  margin-right: auto;
  font-size: 10px;
  color: #f1f2f4;
}

@media only screen and (max-width: 666px) {
  .footer__copyright {
    margin: 0 0 10px 0;
  }
}

.footer__author {
  display: flex;
  align-items: center;
}

.footer__author .footer__stat {
  margin-right: 11px;
  font-size: 10px;
  color: #f1f2f4;
}

@media only screen and (max-width: 666px) {
  .footer__author .footer__stat {
    max-width: 125px;
  }
}

.footer__preview {
  font-size: 0;
}

.footer__author .footer__pic {
  width: 82px;
}

.section {
  position: relative;
}

.section__head {
  position: relative;
  z-index: 3;
  padding-top: 220px;
  background: url("../img/bg-sect-head.jpg") no-repeat 50% 0/cover;
}

@media only screen and (max-width: 1023px) {
  .section__head {
    padding-top: 180px;
  }
}

.section__head:before, .section__head:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.section__head:before {
  z-index: 2;
  opacity: 0.3;
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
  background-image: linear-gradient(120deg, rgba(255, 51, 102, 0), #ff3366);
}

.section__head:after {
  z-index: 1;
  background: rgba(42, 34, 66, 0.8);
}

.section__head .section__center {
  position: relative;
  z-index: 3;
}

.section__wrap {
  display: flex;
  margin: 0 -35px;
  padding: 40px 20px 70px;
}

@media only screen and (max-width: 1023px) {
  .section__wrap {
    display: block;
    margin: 0;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 666px) {
  .section__wrap {
    padding: 20px 0 30px;
  }
}

.section__wrap_pb50 {
  padding-bottom: 50px;
}

.section__wrap_pb100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 1023px) {
  .section__wrap_pb100 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 666px) {
  .section__wrap_pb100 {
    padding-bottom: 85px;
  }
}

.section__wrap_pb120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 1229px) {
  .section__wrap_pb120 {
    padding-bottom: 110px;
  }
}

@media only screen and (max-width: 1023px) {
  .section__wrap_pb120 {
    padding-bottom: 110px;
  }
}

@media only screen and (max-width: 666px) {
  .section__wrap_pb120 {
    padding-bottom: 90px;
  }
}

.section__wrap_pb135 {
  padding-bottom: 135px;
}

@media only screen and (max-width: 1229px) {
  .section__wrap_pb135 {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 1023px) {
  .section__wrap_pb135 {
    padding-bottom: 110px;
  }
}

@media only screen and (max-width: 666px) {
  .section__wrap_pb135 {
    padding-bottom: 100px;
  }
}

.section__body {
  position: relative;
  z-index: 4;
}

.section__body_50 {
  margin-top: -50px;
}

.section__body_70 {
  margin-top: -70px;
}

@media only screen and (max-width: 666px) {
  .section__body_mobile {
    margin-top: -50px;
  }
}

.section__body_50 .article__aside {
  padding-top: 80px;
}

@media only screen and (max-width: 1023px) {
  .section__body_50 .article__aside {
    padding: 0;
  }
}

.section__col {
  flex: 0 0 calc(50% - 70px);
  width: calc(50% - 70px);
  margin: 0 35px;
}

@media only screen and (max-width: 1023px) {
  .section__col {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .section__col:first-child {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 666px) {
  .section__col:first-child {
    margin-bottom: 20px;
  }
}

.section__title {
  position: relative;
  padding-left: 45px;
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1023px) {
  .section__title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .section__title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 666px) {
  .section__title {
    padding-left: 28px;
    font-size: 24px;
  }
}

.section__title:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  height: 47px;
  background: url("../img/symbol.svg") no-repeat 0 0/23px 47px;
}

@media only screen and (max-width: 666px) {
  .section__title:before {
    width: 17px;
    height: 37px;
    background-size: 17px 37px;
  }
}

.section__info {
  font-size: 20px;
  font-weight: 300;
  color: #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .section__info {
    font-size: 14px;
  }
}

.breadcrumbs {
  display: flex;
  padding: 0 20px 20px;
  border-bottom: 1px solid #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .breadcrumbs {
    flex-wrap: wrap;
    padding: 0 0 15px;
  }
}

.breadcrumbs__item {
  position: relative;
  padding-right: 16px;
  font-size: 10px;
  color: #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .breadcrumbs__item {
    margin: 0 10px 5px 0;
  }
}

.breadcrumbs__item:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 6px;
  height: 10px;
  margin-top: -5px;
  background: url("../img/arrow-right-grey.svg") no-repeat 0 0/6px 10px;
}

.breadcrumbs__item:not(:last-child) {
  margin-right: 10px;
}

.breadcrumbs__item:last-child:before {
  display: none;
}

.breadcrumbs__link {
  color: #ABB0BA;
  transition: all .3s;
}

.breadcrumbs__link:hover {
  color: #fff;
}

.diplom__wrap {
  padding: 65px 0 110px;
}

@media only screen and (max-width: 1229px) {
  .diplom__wrap {
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 1023px) {
  .diplom__wrap {
    padding: 50px 0 60px;
  }
}

@media only screen and (max-width: 666px) {
  .diplom__wrap {
    padding: 35px 0 40px;
  }
}

@media only screen and (max-width: 474px) {
  .diplom__wrap {
    margin: 0 -17px;
  }
}

.diplom__list {
  padding: 0 40px;
}

.diplom__preview {
  display: block;
  font-size: 0;
  overflow: hidden;
}

.diplom__preview:hover .diplom__pic {
  transform: scale(1.1);
}

.diplom__pic {
  transition: all .5s;
}

.diplom .owl-item {
  padding: 0 10px;
}

@media only screen and (max-width: 767px) {
  .diplom .owl-item {
    padding: 0 5px;
  }
}

.diplom .owl-prev,
.diplom .owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  background: #ABB0BA;
  transition: all .3s;
  font-size: 0;
}

.diplom .owl-prev .icon,
.diplom .owl-next .icon {
  font-size: 28px;
  fill: #fff;
}

.diplom .owl-prev:hover,
.diplom .owl-next:hover {
  background: #2a2242;
}

.diplom .owl-prev {
  left: 0;
}

.diplom .owl-next {
  right: 0;
}

.objects {
  padding-bottom: 100px;
}

@media only screen and (max-width: 1023px) {
  .objects {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 666px) {
  .objects {
    padding-bottom: 45px;
  }
}

.objects__aside {
  padding-left: 30px;
}

@media only screen and (max-width: 1229px) {
  .objects__aside {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .objects__aside {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
    order: -1;
  }
}

.objects__container {
  max-width: calc(100% - 358px);
}

@media only screen and (max-width: 1023px) {
  .objects__container {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.objects__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 0 5px;
}

@media only screen and (max-width: 1023px) {
  .objects__list {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 666px) {
  .objects__list {
    margin: 0 -8px 30px;
    padding: 0;
  }
}

@media only screen and (max-width: 474px) {
  .objects__list {
    margin: 0 0 40px;
  }
}

.objects__box {
  flex: 0 0 calc((100%/3) - 30px);
  width: calc((100%/3) - 30px);
  margin: 0 15px 20px;
}

@media only screen and (max-width: 1023px) {
  .objects__box {
    flex: 0 0 calc((50% - 30px));
    width: calc((50% - 30px));
  }
}

@media only screen and (max-width: 666px) {
  .objects__box {
    flex: 0 0 calc((50% - 16px));
    width: calc((50% - 16px));
    margin: 0 8px 16px;
  }
}

@media only screen and (max-width: 474px) {
  .objects__box {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 474px) {
  .objects__box:not(:last-child) {
    margin-bottom: 20px;
  }
}

.objects__content {
  padding: 0 20px;
}

@media only screen and (max-width: 666px) {
  .objects__content {
    padding: 0;
  }
}

.objects__aside .objects__content {
  padding: 0;
}

.objects__aside .objects__content h2 {
  margin-bottom: 20px;
}

.objects__in {
  margin-bottom: 50px;
  padding: 0 20px 20px;
  border-bottom: 1px solid #ABB0BA;
}

@media only screen and (max-width: 666px) {
  .objects__in {
    margin-bottom: 35px;
    padding: 0 0 20px;
  }
}

.objects__row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .objects__row {
    flex-wrap: wrap;
  }
}

.objects__row:not(:last-child) {
  margin-bottom: 50px;
}

@media only screen and (max-width: 666px) {
  .objects__row:not(:last-child) {
    margin-bottom: 30px;
  }
}

.objects__video {
  margin-bottom: 40px;
  font-size: 0;
}

@media only screen and (max-width: 474px) {
  .objects__video {
    margin: 0 -17px 30px;
  }
}

.objects__video iframe {
  width: 100%;
}

@media only screen and (max-width: 666px) {
  .objects__video iframe {
    height: 280px;
  }
}

@media only screen and (max-width: 474px) {
  .objects__video iframe {
    height: 230px;
  }
}

.objects__date {
  margin-bottom: 90px;
}

@media only screen and (max-width: 1229px) {
  .objects__date {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 666px) {
  .objects__date {
    margin-bottom: 50px;
  }
}

.objects__foot {
  padding: 0 20px;
}

@media only screen and (max-width: 666px) {
  .objects__foot {
    padding: 0;
  }
}

@media only screen and (max-width: 474px) {
  .objects__foot {
    position: relative;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 474px) {
  .objects__foot .objects__btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.objects__foot .objects__box {
  margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .objects__foot .objects__box {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 666px) {
  .objects__foot .objects__box {
    margin-bottom: 16px;
  }
}

.objects__line {
  display: flex;
  margin: 0 -15px;
}

@media only screen and (max-width: 1023px) {
  .objects__line {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 666px) {
  .objects__line {
    margin: 0 -8px;
  }
}

@media only screen and (max-width: 474px) {
  .objects__line {
    margin: 0;
  }
}

.objects__top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 666px) {
  .objects__top {
    margin-bottom: 20px;
  }
}

.objects__top .objects__info {
  margin: 0 auto 0 0;
  font-size: 28px;
}

@media only screen and (max-width: 666px) {
  .objects__top .objects__info {
    font-size: 24px;
  }
}

@media only screen and (max-width: 474px) {
  .objects__photo {
    margin: 0 -17px;
  }
}

.objects_pb75 {
  padding-bottom: 75px;
}

@media only screen and (max-width: 1023px) {
  .objects_pb75 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 666px) {
  .objects_pb75 {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 474px) {
  .objects_pb75 {
    padding-bottom: 40px;
  }
}

.objects_desc {
  padding-bottom: 70px;
}

@media only screen and (max-width: 1023px) {
  .objects_desc {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .objects_desc {
    padding-bottom: 40px;
  }
}

.objects_desc .objects__box .box__wrap {
  height: 100px;
}

@media only screen and (max-width: 1229px) {
  .objects_desc .objects__box .box__wrap {
    height: 80px;
  }
}

.objects_desc .objects__box .box__title {
  height: 52px;
}

.objects_desc .objects__list {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ABB0BA;
}

.objects_window {
  padding-bottom: 70px;
}

@media only screen and (max-width: 1023px) {
  .objects_window {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .objects_window {
    padding-bottom: 40px;
  }
}

.objects_window .objects__list {
  margin-bottom: 15px;
}

.objects_window .objects__box {
  flex: 0 0 calc(50% - 30px);
  width: calc(50% - 30px);
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .objects_window .objects__box {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 10px 20px;
  }
}

@media only screen and (max-width: 474px) {
  .objects_window .objects__box {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 474px) {
  .objects_window .objects__box:not(:last-child) {
    margin-bottom: 25px;
  }
}

.objects_window .objects__box .box__wrap {
  height: 90px;
}

@media only screen and (max-width: 1023px) {
  .objects_window .objects__box .box__wrap {
    height: 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .objects_window .objects__box .box__title {
    height: 40px;
  }
}

.objects_window .objects__box .box__preview {
  overflow: hidden;
}

.share {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 666px) {
  .share {
    display: block;
  }
}

.share__title {
  margin-right: 60px;
}

@media only screen and (max-width: 1023px) {
  .share__title {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 666px) {
  .share__title {
    margin: 0 0 20px 0;
  }
}

.aside {
  flex: 0 0 358px;
  width: 358px;
}

.container {
  flex: 0 0 calc(100% - 358px);
  width: calc(100% - 358px);
  padding-right: 30px;
}

.photo__big {
  margin-bottom: 20px;
  font-size: 0;
}

.photo__pic {
  width: 100%;
}

.photo__slider {
  padding: 0 25px;
}

.photo__item {
  position: relative;
  font-size: 0;
}

.photo__item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  transition: all .2s;
}

.photo__item:hover:before {
  box-shadow: inset 0 0 0 2px #ff3366;
}

.photo .owl-item {
  padding: 0 5px;
}

.photo .owl-prev,
.photo .owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  background: #ABB0BA;
  transition: all .3s;
  font-size: 0;
}

.photo .owl-prev .icon,
.photo .owl-next .icon {
  font-size: 14px;
  fill: #fff;
}

.photo .owl-prev:hover,
.photo .owl-next:hover {
  background: #2a2242;
}

.photo .owl-prev {
  left: 0;
}

.photo .owl-next {
  right: 0;
}

.box {
  display: block;
  position: relative;
}

.box:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 4px;
  background-image: linear-gradient(to right, #ff3366, #e3097e);
}

.box__preview {
  font-size: 0;
}

.box__pic {
  width: 100%;
  transition: all .5s;
}

.box__wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 148px;
  padding: 15px 25px;
  overflow: hidden;
  background-image: linear-gradient(235deg, rgba(42, 34, 66, 0.4), rgba(42, 34, 66, 0.9));
  transition: all .4s;
}

@media only screen and (max-width: 1229px) {
  .box__wrap {
    height: 120px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 666px) {
  .box__wrap {
    height: 100px;
  }
}

.box__desc {
  padding: 10px 8px 0;
  border-top: 1px solid #fff;
}

@media only screen and (max-width: 666px) {
  .box__desc {
    padding: 10px 0 0;
  }
}

.box__title {
  position: relative;
  height: 78px;
  margin-bottom: 7px;
  padding-right: 20px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1229px) {
  .box__title {
    height: 60px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .box__title {
    padding-right: 0;
  }
}

@media only screen and (max-width: 666px) {
  .box__title {
    height: 48px;
    font-size: 14px;
  }
}

.box__title:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 30px;
  background: url("../img/symbol.svg") no-repeat 0 0/14px 30px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

.box__text {
  display: none;
  height: 72px;
  margin-bottom: 25px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

@media only screen and (max-width: 1229px) {
  .box__text {
    height: 54px;
  }
}

.box__date {
  padding-right: 125px;
  font-size: 14px;
  color: #ABB0BA;
}

@media only screen and (max-width: 1229px) {
  .box__date {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1023px) {
  .box__date {
    padding: 0;
  }
}

.box__btn {
  position: absolute;
  right: 30px;
  bottom: 18px;
  width: 114px;
  height: 33px;
  border: 1px solid #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all .1s .2s;
}

@media only screen and (max-width: 1229px) {
  .box__btn {
    bottom: 15px;
  }
}

body:not(.touch-device) .box:hover .box__wrap {
  height: 100%;
  padding-top: 25px;
}

@media only screen and (max-width: 1229px) {
  body:not(.touch-device) .box:hover .box__wrap {
    padding-top: 15px;
  }
}

body:not(.touch-device) .box:hover .box__title:before {
  visibility: visible;
  opacity: 1;
}

body:not(.touch-device) .box:hover .box__text {
  display: block;
}

body:not(.touch-device) .box:hover .box__btn {
  visibility: visible;
  opacity: 1;
}

body:not(.touch-device) .objects_window .box:hover .box__wrap {
  height: 90px;
  padding-top: 10px;
}

body:not(.touch-device) .objects_window .box:hover .box__title:before {
  visibility: hidden;
  opacity: 0;
}

body:not(.touch-device) .objects_window .box:hover .box__pic {
  transform: scale(1.2);
}

.news {
  padding-bottom: 70px;
}

@media only screen and (max-width: 666px) {
  .news {
    padding-bottom: 45px;
  }
}

.news__container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

@media only screen and (max-width: 666px) {
  .news__container {
    margin-bottom: 35px;
    padding: 0;
  }
}

.news__item {
  flex: 0 0 calc((100%/3) - 30px);
  width: calc((100%/3) - 30px);
  margin: 0 15px 45px;
  color: #2a2242;
}

@media only screen and (max-width: 1229px) {
  .news__item {
    flex: 0 0 calc((50% - 30px));
    width: calc((50% - 30px));
    margin: 0 15px 45px;
  }
}

@media only screen and (max-width: 1023px) {
  .news__item {
    flex: 0 0 calc((50% - 20px));
    width: calc((50% - 20px));
    margin: 0 10px 45px;
  }
}

@media only screen and (max-width: 666px) {
  .news__item {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 666px) {
  .news__item:not(:last-child) {
    margin-bottom: 25px;
  }
}

.news__item:hover .news__pic {
  transform: scale(1.1);
}

.news__item:hover .news__line {
  border-color: #ff3366;
}

.news__preview {
  margin-bottom: 11px;
  overflow: hidden;
  font-size: 0;
}

.news__pic {
  width: 100%;
  transition: all .5s;
}

.news__line {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #ABB0BA;
  transition: all .3s;
}

@media only screen and (max-width: 474px) {
  .news__line {
    display: block;
  }
}

.news__date {
  flex: 0 0 100px;
  width: 100px;
  padding: 0 10px;
}

@media only screen and (max-width: 474px) {
  .news__date {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
  }
}

.news__wrap {
  flex: 0 0 calc(100% - 100px);
  width: calc(100% - 100px);
}

@media only screen and (max-width: 474px) {
  .news__wrap {
    flex: 0 0 100%;
    width: 100%;
  }
}

.news__day {
  font-size: 24px;
}

@media only screen and (max-width: 474px) {
  .news__day {
    margin-right: 5px;
  }
}

.news__month {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.45;
}

@media only screen and (max-width: 666px) {
  .news__month {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 474px) {
  .news__month {
    margin: 0 5px 0 0;
  }
}

.news__year {
  font-weight: 300;
}

@media only screen and (max-width: 474px) {
  .news__year {
    padding-top: 2px;
  }
}

.news__title {
  height: 46px;
  margin-bottom: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .news__title {
    height: 40px;
    font-size: 16px;
  }
}

.news__desc {
  height: 60px;
  font-weight: 300;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .news__desc {
    height: 54px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 666px) {
  .news__desc {
    height: 48px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  padding-top: 2px;
  border: solid 1px #8190b0;
  font-size: 16px;
  line-height: 1;
  color: #2a2242;
  transition: all .3s;
}

@media only screen and (max-width: 666px) {
  .pagination__item {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 474px) {
  .pagination__item {
    flex: 0 0 25px;
    width: 25px;
    height: 30px;
    margin-right: 5px;
  }
}

.pagination__item:hover {
  border-color: #c0c7d7;
  background: #c0c7d7;
}

.pagination__item.active {
  background: #8190B0;
  color: #fff;
}

.pagination__item_arrow {
  border: none;
  background: #2a2242;
}

.pagination__item_arrow:hover {
  background: #4a3c74;
}

.pagination__item:first-child {
  margin-right: 30px;
}

@media only screen and (max-width: 666px) {
  .pagination__item:first-child {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 474px) {
  .pagination__item:first-child {
    margin-right: 10px;
  }
}

.pagination__item:last-child {
  margin: 0 0 0 15px;
}

@media only screen and (max-width: 666px) {
  .pagination__item:last-child {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 474px) {
  .pagination__item:last-child {
    margin-left: 5px;
  }
}

.pagination__item_arrow .icon {
  font-size: 16px;
  fill: #fff;
}

.contacts__body {
  padding: 40px 115px 70px;
}

@media only screen and (max-width: 1229px) {
  .contacts__body {
    padding: 40px 20px 50px;
  }
}

@media only screen and (max-width: 666px) {
  .contacts__body {
    padding: 35px 0 40px;
  }
}

.contacts__row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .contacts__row {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 666px) {
  .contacts__row {
    display: block;
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .contacts__col {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 666px) {
  .contacts__col {
    margin: 0;
  }
}

.contacts__col:first-child {
  flex: 0 0 calc(100% - 425px);
  width: calc(100% - 425px);
}

@media only screen and (max-width: 1023px) {
  .contacts__col:first-child {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }
}

@media only screen and (max-width: 666px) {
  .contacts__col:first-child {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 30px;
  }
}

.contacts__col:nth-child(2) {
  flex: 0 0 425px;
  width: 425px;
}

@media only screen and (max-width: 1023px) {
  .contacts__col:nth-child(2) {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }
}

@media only screen and (max-width: 666px) {
  .contacts__col:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
  }
}

.contacts__item:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .contacts__item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.contacts__title {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .contacts__title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.contacts__title_mb30 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .contacts__title_mb30 {
    margin-bottom: 20px;
  }
}

.contacts__box {
  padding-left: 23px;
}

@media only screen and (max-width: 767px) {
  .contacts__box {
    padding-left: 0;
  }
}

.contacts__box:not(:last-child) {
  margin-bottom: 20px;
}

.contacts__desc {
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .contacts__desc {
    font-size: 14px;
  }
}

.contacts__box .contacts__desc {
  margin-bottom: 8px;
}

.contacts__text {
  font-size: 20px;
  line-height: 1.05;
  color: #2a2242;
}

@media only screen and (max-width: 1023px) {
  .contacts__text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .contacts__text {
    font-size: 16px;
  }
}

.contacts__address {
  margin-bottom: 5px;
}

.contacts__line {
  display: flex;
  align-items: center;
}

.contacts__line:not(:last-child) {
  margin-bottom: 7px;
}

.contacts__link {
  margin-right: 22px;
  font-size: 20px;
  line-height: 1.2;
  color: #2a2242;
  transition: all .3s;
}

@media only screen and (max-width: 1023px) {
  .contacts__link {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .contacts__link {
    margin-right: 10px;
    font-size: 14px;
  }
}

.contacts__link:hover {
  color: #ABB0BA;
}

.contacts__schedule {
  padding-left: 23px;
}

@media only screen and (max-width: 767px) {
  .contacts__schedule {
    padding-left: 0;
  }
}

.contacts__schedule .contacts__text {
  flex: 0 0 160px;
}

@media only screen and (max-width: 767px) {
  .contacts__schedule .contacts__text {
    flex: 0 0 117px;
  }
}

.contacts__social {
  padding-left: 23px;
}

@media only screen and (max-width: 767px) {
  .contacts__social {
    padding-left: 0;
  }
}

.contacts__form {
  padding-left: 23px;
}

@media only screen and (max-width: 767px) {
  .contacts__form {
    padding-left: 0;
  }
}

.contacts__fieldset {
  margin-bottom: 40px;
}

.contacts__field:not(:last-child) {
  margin-bottom: 30px;
}

.contacts__field .field__wrap {
  font-size: 0;
}

.contacts__field .field__label {
  font-weight: 300;
}

.contacts__btn {
  width: 252px;
  height: 40px;
}

@media only screen and (max-width: 767px) {
  .contacts__btn {
    width: 100%;
  }
}

.consult {
  position: relative;
  background: url("../img/bg-consult.jpg") no-repeat 50% 50%/cover;
}

@media only screen and (max-width: 666px) {
  .consult {
    background: #F1F2F4;
  }
}

.consult:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

.consult__center {
  position: relative;
  z-index: 2;
}

.consult__wrap {
  display: flex;
  padding: 40px 5px 70px;
}

@media only screen and (max-width: 1023px) {
  .consult__wrap {
    flex-wrap: wrap;
    padding: 40px 5px 30px;
  }
}

@media only screen and (max-width: 666px) {
  .consult__wrap {
    padding: 35px 0 40px;
  }
}

.consult__item {
  position: relative;
  flex: 0 0 calc(25% - 30px);
  width: calc(25% - 30px);
  margin: 0 15px;
  padding: 0 20px 30px;
}

@media only screen and (max-width: 1229px) {
  .consult__item {
    flex: 0 0 calc(25% - 16px);
    width: calc(25% - 16px);
    margin: 0 8px;
    padding: 0 10px 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .consult__item {
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 666px) {
  .consult__item {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 666px) {
  .consult__item:not(:last-child) {
    margin-bottom: 25px;
  }
}

.consult__item:before {
  content: "";
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #fff;
}

.consult__top {
  margin-bottom: 6px;
  padding-right: 40px;
}

.consult__preview {
  display: inline-flex;
  width: 104px;
  height: 104px;
  overflow: hidden;
  margin-bottom: 20px;
  border: 2px solid #fff;
  font-size: 0;
}

.consult__pic {
  width: 100px;
  min-height: 100%;
}

.consult__man {
  margin-bottom: 10px;
  font-size: 18px;
}

.consult__post {
  height: 36px;
  overflow: hidden;
  font-size: 14px;
  color: #ABB0BA;
}

.consult__bot {
  padding-top: 20px;
  border-top: 1px solid #ABB0BA;
}

.consult__text {
  white-space: nowrap;
  transition: all .3s;
}

.consult__text_mail {
  text-decoration: underline;
}

.consult__line {
  display: inline-flex;
  align-items: center;
}

.consult__line:not(:last-child) {
  margin-bottom: 6px;
}

.consult__icon {
  flex: 0 0 18px;
  margin-right: 18px;
  text-align: center;
  font-size: 0;
}

.consult__icon .icon {
  fill: #ABB0BA;
  transition: all .3s;
}

.consult__icon .icon-mobile {
  font-size: 19px;
}

.consult__icon .icon-phone {
  font-size: 16px;
}

.consult__icon .icon-mail {
  font-size: 17px;
}

.consult__icon .icon-skype {
  font-size: 18px;
}

.consult__phones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.consult__phones .consult__text {
  font-size: 18px;
  line-height: 1;
  color: #2a2242;
}

.consult__phones .consult__line:hover .consult__text {
  color: #ABB0BA;
}

.consult__other {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.consult__other .consult__line:hover .icon {
  fill: #2a2242;
}

.consult__other .consult__line:hover .consult__text {
  text-decoration: none;
  color: #2a2242;
}

.consult__other .consult__text {
  font-size: 14px;
  color: #8190B0;
}

.map {
  padding: 70px 0;
}

@media only screen and (max-width: 1023px) {
  .map {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 666px) {
  .map {
    padding: 0 0 40px;
  }
}

.map__center {
  max-width: 1190px;
}

@media only screen and (max-width: 666px) {
  .map__center {
    padding: 0;
  }
}

.map__box {
  width: 100%;
  height: 595px;
}

@media only screen and (max-width: 1023px) {
  .map__box {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .map__box {
    height: 350px;
  }
}

@media only screen and (max-width: 666px) {
  .map__box {
    height: 270px;
  }
}

.article {
  padding-bottom: 80px;
}

@media only screen and (max-width: 1023px) {
  .article {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 666px) {
  .article {
    padding-bottom: 40px;
  }
}

.article__row {
  display: flex;
  padding: 0 20px;
}

@media only screen and (max-width: 1023px) {
  .article__row {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 666px) {
  .article__row {
    padding: 0;
  }
}

.article__content blockquote:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1023px) {
  .article__container {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 40px;
    padding: 0;
  }
}

.article__aside {
  padding-top: 110px;
}

@media only screen and (max-width: 1023px) {
  .article__aside {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
  }
}

.article__info {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 400;
}

@media only screen and (max-width: 666px) {
  .article__info {
    font-size: 24px;
  }
}

.article__list {
  margin-bottom: 45px;
}

@media only screen and (max-width: 1023px) {
  .article__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 30px;
  }
}

@media only screen and (max-width: 666px) {
  .article__list {
    margin: 0 -8px 10px;
  }
}

@media only screen and (max-width: 474px) {
  .article__list {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .article__list .article__box {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 0 15px 15px;
  }
}

@media only screen and (max-width: 666px) {
  .article__list .article__box {
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px 16px;
  }
}

@media only screen and (max-width: 474px) {
  .article__list .article__box {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
}

.article__box:not(:last-child) {
  margin-bottom: 23px;
}

@media only screen and (max-width: 474px) {
  .article__box:not(:last-child) {
    margin-bottom: 20px;
  }
}

.article__btns {
  text-align: right;
}

@media only screen and (max-width: 1023px) {
  .article__btns {
    text-align: left;
  }
}

.window {
  padding-bottom: 70px;
}

@media only screen and (max-width: 1023px) {
  .window {
    padding-bottom: 55px;
  }
}

.window__row {
  display: flex;
  padding: 0 20px;
}

@media only screen and (max-width: 1023px) {
  .window__row {
    display: block;
  }
}

@media only screen and (max-width: 666px) {
  .window__row {
    padding: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .window__aside, .window__container {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
  }
}

.window__container {
  max-width: calc(100% - 358px);
}

@media only screen and (max-width: 1023px) {
  .window__container {
    max-width: 100%;
    padding-bottom: 30px;
  }
}

.window__content {
  margin-bottom: 40px;
}

.window__content h2 {
  margin-bottom: 20px;
}

.window__photo {
  margin-bottom: 85px;
}

@media only screen and (max-width: 1229px) {
  .window__photo {
    margin-bottom: 65px;
  }
}

@media only screen and (max-width: 1023px) {
  .window__photo {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 666px) {
  .window__photo {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 474px) {
  .window__photo {
    margin: 0 -17px 30px;
  }
}

.window__photo .photo__big {
  border: 1px solid #f1f2f4;
}

.window__video {
  margin-bottom: 50px;
  font-size: 0;
}

@media only screen and (max-width: 666px) {
  .window__video {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 474px) {
  .window__video {
    margin: 0 -17px 30px;
  }
}

.window__video iframe {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .window__video iframe {
    height: 300px;
  }
}

@media only screen and (max-width: 666px) {
  .window__video iframe {
    height: 250px;
  }
}

.window__control {
  margin-bottom: 50px;
  padding: 20px 35px 12px;
  border-width: 3px 1px;
  border-style: solid;
  border-color: #ff3366 #f1f2f4 #7f7a8e;
  background: #fff;
}

@media only screen and (max-width: 666px) {
  .window__control {
    margin-bottom: 35px;
  }
}

.window__title {
  position: relative;
  padding-left: 25px;
  font-size: 24px;
  font-weight: 400;
}

.window__title:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 30px;
  background: url("../img/symbol.svg") no-repeat 0 0/14px 30px;
}

.window__control .window__title {
  margin-bottom: 15px;
  padding: 0 0 15px 25px;
  border-bottom: 1px solid #2a2242;
}

.window__menu {
  padding-left: 25px;
}

@media only screen and (max-width: 666px) {
  .window__menu {
    padding-left: 0;
  }
}

.window__link {
  display: flex;
  align-items: center;
  min-height: 45px;
  font-size: 16px;
  font-weight: 300;
  color: #2a2242;
  transition: all .3s;
}

.window__link:hover {
  color: #ABB0BA;
}

.window__link.active {
  font-size: 20px;
  font-weight: 700;
  color: #2a2242;
}

.window__link:not(:last-child) {
  border-bottom: 1px solid #ABB0BA;
}

.window__head {
  padding: 19px 35px;
  background: #2a2242;
}

@media only screen and (max-width: 666px) {
  .window__head {
    padding: 15px 25px;
  }
}

.window__form .window__title {
  color: #fff;
}

@media only screen and (max-width: 666px) {
  .window__form .window__title {
    font-size: 20px;
  }
}

.window__body {
  padding: 30px 35px 33px;
  border-bottom: 3px solid #7F7A8E;
  background-color: #f1f2f4;
}

@media only screen and (max-width: 666px) {
  .window__body {
    padding: 20px 15px;
  }
}

.window__field {
  margin-bottom: 20px;
}

.window__field .field__textarea {
  height: 100px;
}

.window__btn {
  width: 100%;
  height: 40px;
}

.action {
  padding-bottom: 70px;
}

@media only screen and (max-width: 1023px) {
  .action {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .action {
    padding-bottom: 45px;
  }
}

.action__list {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .action__list {
    margin-bottom: 35px;
  }
}

.action__item {
  display: flex;
  align-items: center;
  background: #fff;
  border: solid 1px #f1f2f4;
  transition: all .3s;
}

@media only screen and (max-width: 666px) {
  .action__item {
    display: block;
  }
}

.action__item:hover {
  box-shadow: 0 3px 25px rgba(171, 176, 186, 0.35);
}

.action__item:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1023px) {
  .action__item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.action__preview {
  flex: 0 0 40%;
  width: 40%;
  font-size: 0;
}

@media only screen and (max-width: 1229px) {
  .action__preview {
    flex: 0 0 45%;
    width: 45%;
  }
}

@media only screen and (max-width: 666px) {
  .action__preview {
    flex: 0 0 100%;
    width: 100%;
  }
}

.action__pic {
  width: 100%;
}

.action__wrap {
  padding: 10px 20px 5px 30px;
}

@media only screen and (max-width: 666px) {
  .action__wrap {
    padding: 15px;
  }
}

.action__date {
  margin-bottom: 15px;
  color: #ABB0BA;
}

.action__title {
  margin-bottom: 15px;
  color: #2a2242;
}

@media only screen and (max-width: 1229px) {
  .action__title {
    height: 42px;
    margin-bottom: 10px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1023px) {
  .action__title {
    margin-bottom: 20px;
  }
}

.action__text {
  height: 62px;
  overflow: hidden;
  margin-bottom: 25px;
  color: #ABB0BA;
}

@media only screen and (max-width: 1229px) {
  .action__text {
    height: 54px;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1023px) {
  .action__text {
    display: none;
  }
}

@media only screen and (max-width: 666px) {
  .action__text {
    display: block;
    height: 48px;
  }
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  min-width: 320px;
  padding: 20px;
  background-color: rgba(42, 34, 66, 0.8);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  z-index: 999;
  -webkit-tap-highlight-color: transparent;
}

.popup.visible {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
}

.popup.animation .popup__wrap {
  opacity: 1;
  transform: scale(1);
}

.popup__wrap {
  position: relative;
  max-width: 380px;
  width: 100%;
  padding: 30px 20px;
  margin: auto;
  border-bottom: 3px solid #ff3366;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity .7s, transform .7s;
  background-color: #fff;
  text-align: center;
}

.popup__title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}

@media only screen and (max-width: 767px) {
  .popup__title {
    font-size: 22px;
  }
}

.popup__text {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .popup__text {
    font-size: 16px;
  }
}

.popup__close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -30px;
  right: -30px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .popup__close {
    right: 0;
  }
}

.popup__close .icon {
  font-size: 20px;
  fill: #fff;
  transition: fill .3s;
}

.popup__close:hover .icon {
  fill: #ff3366;
}
